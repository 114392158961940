import {useState} from 'react';
import classes from './Question.module.css';
import plus from "../../../../../FILES/Images/Plus.png";
import minus from "../../../../../FILES/Images/Minus.png";

function Question(props){
    const [isOpened, setIsOpened] = useState(false);
    const openQuestion_Handler = () =>{
        setIsOpened((prevState) => !prevState);
    }
return (
    <div className={classes.container}>
        <div className={classes.header}>
            <div className={classes.plusminus} onClick={openQuestion_Handler}>
                <img src={`${isOpened ? minus: plus}`} className={classes.pic} alt={'plus minus sign'}/>
            </div>
            <div className={classes.question}>
                <h3 style={{color:`${isOpened ? 'blue': 'black'}`}}>{props.question}</h3>
            </div>
        </div>
        <div className={`${classes.footer} ${isOpened ? classes.opened : ''} `}>
            <p>{props.answer}</p>
        </div>
    </div>
);
}


export default Question;    