import { Fragment } from 'react';

function PI (props){

    return (
        <Fragment>
            <p style={{textIndent: '5vw'}}>{props.t}</p>
        </Fragment>
    );
};

export default PI;