import classes from './Terms.module.css';
import Background from '../../COMPONENTS/UI/Background/Background';
import Logo from '../../COMPONENTS/UI/Logo';
import Footer from '../../COMPONENTS/UI/Footer/Footer';
import useGetTranslatedText from '../../HOOKS/useGetTranslatedText'; 

import PIS from '../../COMPONENTS/UI/Texts/PIS';
import PI from '../../COMPONENTS/UI/Texts/PI';
import PS from '../../COMPONENTS/UI/Texts/PS';
import P from '../../COMPONENTS/UI/Texts/P';
// import P2I from '../../COMPONENTS/UI/Texts/P2I';
import P2IS from '../../COMPONENTS/UI/Texts/P2IS';
import PB from '../../COMPONENTS/UI/Texts/PB';
import PBS from '../../COMPONENTS/UI/Texts/PBS';
// import H3 from '../../COMPONENTS/UI/Texts/H3';
import S from '../../COMPONENTS/UI/Texts/S';
// import S2 from '../../COMPONENTS/UI/Texts/S2';

function Terms() {
    
    const text = useGetTranslatedText('termsPage_TextTranslations.js');


  return (
    <Background>    
        <Logo/>    
        <div className={classes.container}>
            <PS t={text[1]}/>
            <PBS t={text[2]}/>
            <PS t={text[3]}/>
            <PIS t={text[4]}/>
            <P t={text[5]}/>
            <PIS t={text[6]}/>
                    
            {/* 1 */}
            <S/>
            <PBS t={text[7]}/>
                <PB t={text[8]}/>
                    <PIS t={text[9]}/>
                <PB t={text[10]}/>
                    <PIS t={text[11]}/>
                <PB t={text[12]}/>
                    <PIS t={text[13]}/>    
                <PB t={text[14]}/>
                    <PI t={text[15]}/>
                    <PI t={text[16]}/>
                    <PI t={text[17]}/>
                    <PIS t={text[18]}/>
                <PB t={text[19]}/>
                    <PIS t={text[20]}/>  
                <PB t={text[21]}/>
                    <PIS t={text[22]}/> 
                <PB t={text[23]}/>
                    <PIS t={text[24]}/>                     

            {/* 2 */}
            <S/>
            <PBS t={text[27]}/>
                <PIS t={text[28]}/>
            <PB t={text[29]}/>
                <PIS t={text[30]}/>  
            <PB t={text[31]}/>
                <PIS t={text[32]}/>  
            <PB t={text[33]}/>
                <PIS t={text[34]}/> 
            <PB t={text[35]}/>
                <PI t={text[36]}/>                 
                <PI t={text[37]}/>
                <PIS t={text[38]}/>
            <PB t={text[39]}/>
                <PI t={text[40]}/>                 
                <PI t={text[41]}/>
                <PIS t={text[42]}/>
            <PB t={text[43]}/>                
                <PI t={text[44]}/>
                <PIS t={text[45]}/>
            <PB t={text[46]}/>
                <PIS t={text[47]}/>

            {/* 3 */}
            <S/>
            <PBS t={text[50]}/>
            <PB t={text[51]}/>
                <PIS t={text[52]}/>  
            <PB t={text[53]}/>
                <PIS t={text[54]}/>  
            <PB t={text[55]}/>
                <PI t={text[56]}/> 
                <PIS t={text[57]}/> 
            <PB t={text[58]}/>
                <PIS t={text[59]}/>  
            <PB t={text[60]}/>
                <PIS t={text[61]}/> 
            <PB t={text[62]}/>
                <PIS t={text[63]}/>  
            <PB t={text[64]}/>
                <PIS t={text[65]}/> 

            {/* 4 */}
            <S/>
            <PBS t={text[68]}/>
            <PB t={text[69]}/>
                <PI t={text[70]}/>                  
                <PI t={text[71]}/>
                <PI t={text[72]}/>
                <PIS t={text[73]}/>
            <PB t={text[74]}/>
                <PIS t={text[75]}/>
            <PB t={text[76]}/>
                <PI t={text[77]}/>
                <PI t={text[78]}/>
                <PIS t={text[79]}/> 
            <PB t={text[80]}/>
                <PI t={text[81]}/>
                <PI t={text[82]}/>
                <PIS t={text[83]}/>     

            {/* 5 */}
            <S/>
            <PBS t={text[86]}/>
            <PB t={text[87]}/>
                <PIS t={text[88]}/>  
            <PB t={text[89]}/>
                <PIS t={text[90]}/> 
            <PB t={text[91]}/>
                <PIS t={text[92]}/> 
            <PB t={text[93]}/>
                <PI t={text[94]}/>
                <PI t={text[95]}/>
                <PI t={text[96]}/>
                <PI t={text[97]}/>
                <PIS t={text[98]}/>

            {/* 6 */}
            <S/>
            <PBS t={text[101]}/>
                <PIS t={text[102]}/>  
            <PB t={text[103]}/>
                <PI t={text[104]}/>
                <P2IS t={text[105]}/>
            <PB t={text[106]}/>
                <PIS t={text[107]}/>
            <PB t={text[108]}/>
                <PIS t={text[109]}/>  


            {/* 7 */}
            <S/>
            <PBS t={text[112]}/>
                <PI t={text[113]}/>
                <PI t={text[114]}/>
                <PI t={text[115]}/>
                <PI t={text[116]}/>
                <PI t={text[117]}/>
                <PI t={text[118]}/>
                <PI t={text[119]}/>
                <PI t={text[120]}/>
                <PI t={text[121]}/>
                <PI t={text[122]}/>
                <PI t={text[123]}/>
                <PI t={text[124]}/>
                <PI t={text[125]}/>
                <PI t={text[126]}/>
                <PI t={text[127]}/>
                <PI t={text[128]}/>
                <PI t={text[129]}/>
                <PI t={text[130]}/>
                <PI t={text[131]}/>
                <PI t={text[132]}/>
                <PI t={text[133]}/>
                <PI t={text[134]}/>
                <PI t={text[135]}/>
                <PI t={text[136]}/>
                <PIS t={text[137]}/>
        </div>
        <Footer/>
    </Background>
  );
}

export default Terms;
