import { Fragment } from 'react';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
import { scrollToSection } from '../../../../UTILS/scrollToSection';
// import classes from './NavigationMenu.module.scss';

function NavigationMenu1Items (props) {
    const text = useGetTranslatedText('general_TextTranslations.js');  
    const onClick_Handler = (section) => {
        scrollToSection(section);
        props.onClose();
    }
    return (
        <Fragment> 
            <li className={props.className} onClick={() =>onClick_Handler('why_edneos')}>{text[4]}</li>
            <li className={props.className} onClick={() =>onClick_Handler('product')}>{text[5]}</li>
            <li className={props.className} onClick={() =>onClick_Handler('solution')}>{text[6]}</li>
            <li className={props.className} onClick={() =>onClick_Handler('contact')}>{text[9]}</li>
        </Fragment> 
    );
};

export default NavigationMenu1Items;
