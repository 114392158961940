import useGetTranslatedText from '../../../HOOKS/useGetTranslatedText'; 
import { Link } from 'react-router-dom';
import classes from './Footer.module.scss';
import textLogo from "../../../FILES/Images/textLogo.png";

function Footer(){ 
    const text = useGetTranslatedText('general_TextTranslations.js');
    const link = useGetTranslatedText('links_TextTranslations.js');

return(    
    <section className={classes.footerx}>             
        <div className={classes.container}>

            <div className={classes.containerUp}>
                <img src={textLogo} className={classes.itemUp} alt={'Text Logo'}/>
                <div className={classes.itemUp}><a style={{color:'white'}} href="mailto:hello@edneos.com">hello@edneos.com</a></div>
                <div className={classes.itemUp}><a style={{color:'white'}} href="mailto:hello@edneos.com">{text[9]}</a></div>
            </div>

            <div className={classes.line}></div>

            <div className={classes.containerDown}>
                <div className={classes.itemDown}>{text[0]}</div>
                <Link to={`/${link[0]}`} >                
                    <div className={classes.itemDown}> {text[1]}</div>
                </Link>
                <Link to={`/${link[1]}`} >                
                    <div className={classes.itemDown}> {text[2]}</div>
                </Link>
            </div>
            {/* <p>{text[0]}</p>
            <Link to={`/${link[0]}`}>                
                <div style={{color: 'black'}}> {text[1]}</div>
            </Link>
            <Link to={`/${link[1]}`}>                
                <div style={{color: 'black'}}> {text[2]}</div>
            </Link>
            <p></p>
            <p></p> */}

        </div>
    </section>
);
};

export default Footer;