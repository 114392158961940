import classes from './DropButton.module.scss';
import {useState, useEffect} from 'react';

function DropButton (props) {
    const [menuIsOpened, setMenuIsOpened] = useState(false);
    const [dropMenuClass, setDropMenuClass] = useState();

    useEffect(() => {    
    if (!props.onClose) {         
        setMenuIsOpened(false)
    }
}, [props.onClose]);

    // useEffect(() => {    
    //     const onScroll = () => {                   
    //         if (window.pageYOffset < 80) {setDropMenuClass(); }
    //         if (window.pageYOffset > 100){setDropMenuClass(classes.active); }
    //     };
    //     window.removeEventListener('scroll', onScroll);      
    //     window.addEventListener("scroll", onScroll, {passive: true} );
    //     return () => window.removeEventListener('scroll', onScroll);    
    // }, []);

    const menuOnClick_Handler = () => {
        setMenuIsOpened((prevState) => !prevState);  
        props.menuIsOpened(!menuIsOpened);
        // document.main.style.filter= !menuIsOpened ? 'blur(8px)' : null ;
        // document.querySelectorAll('body >*:not(.logo)').forEach(e => e.style.filter = "blur(8px)");
    };
          
    return (
        <div onClick={menuOnClick_Handler} className={`${classes.dropMenu} ${classes.showOnMobile} ${dropMenuClass}`}>
            <div className={classes.containerMenuBars}>
                <span className={menuIsOpened ? classes.open : ''}></span>
                <span className={menuIsOpened ? classes.open : ''}></span>
                <span className={menuIsOpened ? classes.open : ''}></span>
            </div>         
        </div>  
    );
};

export default DropButton;
