// import {useNavigate } from 'react-router-dom';
import classes from './NavigationMenu.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { storeGeneral_Actions } from '../../../../STORE/storeGeneral';


function LanguageChanger (props) {
    const activeLang = useSelector((state) => state.generalStore.selectedLanguage); 
    const dispatch = useDispatch();    
    // const navigate = useNavigate();

    const onChangeLang_Handler = (lang) =>{
        dispatch( storeGeneral_Actions.changeLang({lang:lang}));        
        // navigate('/');
    };

return (
    <li className={classes.liMainNav}>              
        <span className={activeLang === 'en' ? classes.langActiveColor : classes.langInactiveColor}  onClick={() => onChangeLang_Handler('en')}>ENG </span>
        <span style={{color:'inherit'}}> / </span>
        <span className={activeLang === 'ro' ? classes.langActiveColor : classes.langInactiveColor}  onClick={() => onChangeLang_Handler('ro')}> RO</span>
    </li>
);
};

export default LanguageChanger;
