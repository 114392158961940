import classes from './TryApp.module.css';

import appIcon1 from "../../../../FILES/Images/appIcon1c.png";
import appIcon2 from "../../../../FILES/Images/appIcon2c.png";
import appIcon3 from "../../../../FILES/Images/appIcon3c.png";
// import appStoreImg from "../../../../FILES/Images/AppStore.png";
// import googlePlayImg from "../../../../FILES/Images/GooglePlay.png";

import AppCard from './Components/AppCard';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
// import ButtonWithImg from '../../../../COMPONENTS/UI/Buttons/ButtonWithImg';
import ButtonWithArrow from '../../../../COMPONENTS/UI/Buttons/ButtonWithArrow';
import SectionCard from '../../../../COMPONENTS/UI/SectionCard';
import colors from '../../../../UTILS/colors';
// import { scrollToSection } from '../../../../UTILS/scrollToSection';
import AppStoreSvgIcon from '../../../../COMPONENTS/SVG/Icons/AppStoreSvgIcon';
import GooglePlaySvgIcon from '../../../../COMPONENTS/SVG/Icons/GooglePlaySvgIcon';

function TryApp(props){ 
    const text = useGetTranslatedText('homePage_TextTranslations.js');
    const linkGooglePlay = 'https://play.google.com/store/apps/details?id=com.edneos';
    const linkAppStore = 'https://apps.apple.com/us/app/edneos/id1623354808';
    const linkMentorAccount = 'https://app.edneos.com/auth/create-account';
    const linkInstitutionalAccount = 'https://app.edneos.com/auth/create-school-account';
return (
    <SectionCard flexDirection={`column`} id={props.id}>
            <div className={classes.containerText}>                  
                <h1><span>{text[76]}</span> <span  style={{color:colors.primaryColor}}>{text[77]}</span> <span>{text[78]}</span> </h1>
                <div className={classes.par}>{text[79]}</div>        
            </div>
            <div className={classes.containerCards}>
                <AppCard name={text[80]} question={text[81]} picture={appIcon1} buttons={[{icon: AppStoreSvgIcon, text:text[117], link:linkAppStore},{icon:GooglePlaySvgIcon, text: text[118], link:linkGooglePlay}]} title={text[82]} textList ={[text[83], text[84], text[85], text[86]]} color={`#2C77AE`} backColor={`#2C77AE`}/> 
                <AppCard name={text[87]} question={text[88]} picture={appIcon2} buttons={[{text:text[119], link:linkMentorAccount}]} title={text[89]} textList={[text[89], text[90], text[110], text[111]]}  color={`#2D9FD5`} backColor={`#2D9FD5`}/> 
                <AppCard name={text[91]} question={text[92]} picture={appIcon3} buttons={[{text:text[120], link:linkInstitutionalAccount}]} title={text[93]} textList={[text[93], text[112], text[113], text[114]]} color={`#8CC3EA`} backColor={`#8CC3EA`}/>
            </div>
            <div className={classes.installAppButtons}>
                {/* <ButtonWithImg imgSrc={googlePlayImg}/> */}                
                {/* <ButtonWithImg imgSrc={appStoreImg}/>     */}
                {/* <ButtonWithArrow  onClick={() =>scrollToSection('contact')}><span>{text[115]}</span></ButtonWithArrow> */}
            </div>         
    </SectionCard> 

);
}


export default TryApp;