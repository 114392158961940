import IconSVG from '../IconSVG';

function AppStoreSvgIcon(props) {  
    
    const lineWidth=1;
    const svgWidth=20;
    const svgHeight=25;
        
    const d1="M13.0668 4.17545C13.9613 3.10477 14.4004 1.7262 14.2901 0.335449C12.9424 0.472062 11.6989 1.12228 10.8177 2.15122C10.3747 2.65726 10.0362 3.24589 9.82151 3.88325C9.60686 4.52062 9.52035 5.19413 9.56695 5.86505C10.2414 5.87445 10.9089 5.72666 11.5163 5.4334C12.1238 5.14013 12.6547 4.70945 13.0668 4.17545Z";
    const d2="M16.0338 13.085C16.0127 12.2473 16.2013 11.4176 16.5823 10.6713C17.043 9.79392 17.7235 9.05109 18.5572 8.51542C18.0767 7.81131 17.4379 7.22965 16.6919 6.81714C15.946 6.40462 15.1137 6.17274 14.2619 6.1401C12.4242 5.9481 10.6797 7.23725 9.74714 7.23725C8.81457 7.23725 7.38279 6.1401 5.85777 6.19496C4.85409 6.22744 3.87638 6.522 3.0218 7.04938C2.16722 7.57675 1.46553 8.31858 0.986451 9.20113C0.666455 9.76882 0.42647 10.378 0.273308 11.0114C-0.604407 14.4948 0.767022 18.7737 2.46759 21.2971C3.46051 22.7508 4.66188 24.3911 6.19239 24.3307C7.72291 24.2704 8.24954 23.3488 10.0324 23.3488C11.8153 23.3488 12.3474 24.3307 13.9273 24.3033C15.5071 24.2759 16.5823 22.8276 17.5533 21.352C18.2602 20.298 18.8143 19.1492 19.199 17.9399C18.2557 17.5272 17.4536 16.8478 16.8912 15.9853C16.3289 15.1227 16.0308 14.1147 16.0338 13.085Z";
    return (
        <IconSVG 
        // id={"appStore1"}
        id={Math.random()}
            svgWidth={svgWidth}
            svgHeight={svgHeight}
            lineWidth={lineWidth}
            d1={d1}
            d2={d2} 
            fillColor = {props.fillColor}
            className={props.className}
            style={{...props.style}}
        />
    );
};

export default AppStoreSvgIcon;