
// import classes from './HomeNavOption.module.scss';
import classes from './NavigationMenu.module.scss';
import { useNavigate } from 'react-router-dom';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
import LanguageChanger from './LanguageChanger';

function NavigationMenuSimple (props) {
    const text = useGetTranslatedText('general_TextTranslations.js');  
    const navigate  = useNavigate();

return (
    <div className={classes.navListContainer}>
        <ul className={classes.ulMainNav} > 
            <li className={classes.liMainNav} onClick={() => navigate('/')}>{text[31]}</li>
            <LanguageChanger/>
        </ul>     
    </div>
);
};

export default NavigationMenuSimple;
