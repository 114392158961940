import axios from "axios";
import classes from './DeleteAccountForm.module.scss';

import { useState, useRef } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Input from "../../../COMPONENTS/UI/Inputs/Input";
import Button from '../../../COMPONENTS/UI/Buttons/Button';

import useGetTranslatedText from '../../../HOOKS/useGetTranslatedText'; 

 
function DeleteAccountForm () {
    const text = useGetTranslatedText('general_TextTranslations.js');
    const errorText = useGetTranslatedText('errors_TextTranslations.js');
    
    const [isLoading, setIsLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const navigate = useNavigate ();  
    
    // const params = useParams(); 
    // const { refId }= params;
    
    const emailInputRef = useRef();
    const codeInputRef = useRef();


    const validateEmail = (email) => {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        const enteredEmail = emailInputRef.current?.value;
        const enteredCode = codeInputRef.current?.value;
        // Validation
        if(!emailSent && !enteredEmail){ alert(errorText[1]); return; }
        if(!emailSent && !validateEmail(enteredEmail)){alert(errorText[2]); return; }
        if(!emailSent && enteredEmail.length < 8 ){alert(errorText[3]); return; }
        if(emailSent && enteredCode.length < 5 ){alert(errorText[7]); return; }

        const formData = new FormData();
        if (emailSent){
            formData.append('code', enteredCode);
        }else{
            formData.append('email', enteredEmail);
        }       

        setIsLoading(true);
        // LoadingSpinner

        try {
            const response = await axios.post(
                emailSent ? 'https://api.edneos.com/api/LandingRemoveProfileFinish.php' : 'https://api.edneos.com/api/LandingRemoveProfileStart.php',
                formData,
                 {headers: {'Content-Type': 'multipart/form-data'}}
            );
            if(response.data.status==='ok'){
                setIsLoading(false);
                console.log('response',response);
                console.log('response.data',response.data);                
                if (!emailSent){
                    setEmailSent(true);
                    alert(text[19])
                }else{
                    alert(text[20])
                    navigate('/');
                }
                return;
            }
            if(response.data.error){
                setIsLoading(false);
                alert(response.data.error || errorText[8]);
                console.log(response.data.error);
                return;
            }
            setIsLoading(false);
            alert(errorText[5]);
            
        } catch (err) {
            setIsLoading(false);
            alert(errorText[5]);
            alert('err.message',err.message);
           
        }
    }

    return (
        // <GlassCardClose navigateOnClose={'/'}>
        <div className={classes.card}>
            <h1 className={classes.h1}>{text[18]}</h1>
            <form onSubmit={submitHandler} method="POST">
                <div>{text[24]}</div>
                {/* {!emailSent && <InputLabelEffect className={classes.input} label={text[12]} labelStyle={classes.label} input={{name:'email', id: 'email', type: 'email', required:true }} ref={emailInputRef} />} */}
                {/* {emailSent && <InputLabelEffect className={classes.input} label={text[21]} labelStyle={classes.label} input={{name:'code', id: 'code', type: 'number', required:true }} ref={codeInputRef}/>} */}

                {!emailSent && <Input className={classes.input} input={{name:'email', id: 'email', type: 'email', required:true, placeholder: text[12]}} ref={emailInputRef} />}
                {emailSent && <Input  className={classes.input} input={{name:'code', id: 'code', type: 'number', required:true, placeholder: text[21] }} ref={codeInputRef}/>}


                {/* <InputLabelEffect label={text[21]} input={{name:'code', id: 'code', type: 'number', required:true }} ref={codeInputRef}/> */}
                <div className={classes.actions}>
                    {!isLoading && <Button type='submit' >{emailSent ? text[23] : text[22]}</Button>}
                    {isLoading && <p>{text[25]}</p>}
                </div>
                <div>
                    {/* <span>{text[26]}</span>                     */}
                        {/* <span style={{color: 'blue', textDecoration: 'underline' }}>{text[122]}</span> */}
                        <span>{text[26]}<a href="mailto:hello@edneos.com">hello@edneos.com</a></span>
                </div>                
            </form>
        </div>
        // </GlassCardClose>    
    );
};

export default DeleteAccountForm;
