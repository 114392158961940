
const generalTextTranslations= {
   ////////////// - Footer - //////////////
   0: {
         en:`© 2024 EdNeos`,
         ro:`© 2024 EdNeos`
         // en:`© 2023 EdNeos. All rights reserved`,
         // ro:`© 2023 EdNeos. Toate drepturile rezervate`         
      },
   1: {
         en:`Terms and conditions`,
         ro:`Termeni și condiții`
      },
   2: {
         en:`Privacy policy`,
         ro:`Politica de confidențialitate`
      },     
   3: {
         en:`Developed by`,
         ro:`Creat de`
      },
   4: {
         en:`Why Edneos?`,
         ro:`De ce EdNeos?`
      },
   5: {
         en:`For Learners`, 
         ro:`Pentru cursanți` 
      },
 
   6: {
         en:`For Teachers`, 
         ro:`Pentru profesori`
      },
   7: {
         en:`Pricing`,
         ro:`Prețuri`
      },
   8: {
         en:`Our vision`,
         ro:`Viziune`
      },                              
   9: {
         en:`Contact Us`,
         ro:`Contactează-ne`
      },      
   10:{
         en:`Get started`,
         ro:`Începe acum`
      },        
///////////////// Login - SignUp  ///////////////////
   11:{
         en:`Register new user`,
         ro:`Inregistrare utilizator nou`
      },
   12:{
         en:`E-mail`,
         ro:`E-mail`
      },
   13:{
         en:`Password`,
         ro:`Parola`
      },
   14:{
         en:`Confirm Password`,
         ro:`Confirmă Parola`
      },
   15:{
         en:`Create account`,
         ro:`Creare cont`
      },  
   16:{
         en:`Signing up...`,
         ro:`Inregistrare în progres ...`
      },    
   17:{
         en:`You have successfully registered!`,
         ro:`Ați fost înregistrat cu succes!`
      }, 
   18:{
         en:`Delete Account`,
         ro:`Ștergere cont`
      },  
   19:{
         en:`The deletion confirmation code has been successfully sent to the email address provided. Check your email and enter the code you received below.`,
         ro:`Codul de confirmare pentru ștergere a fost trimis cu succes la adresa de e-mail furnizată. Verificați e-mailul și introduceți mai jos codul primit.`
      },                              
   20:{
         en:`Your account, including your personal data, has been successfully deleted!`,
         ro:`Contul dumneavoastră, inclusiv datele personale aferente, a fost șters cu succes!`
      },  
   21:{
         en:`Verification code`,
         ro:`Cod de verificare`
      },
   22:{
         en:`Delete account`,
         ro:`Șterge cont`
      },            
   23:{
         en:`Send verification code`,
         ro:`Trimite codul de verificare`
      }, 
   24:{
         en:`If you have an account created on Edneos and you want to delete both the account and the personal data and other data related to the account, use the form to start this process. We will send you a verification code on your email which can be associated with your account and after entering this token you will be able to complete the process.`,
         ro:`În cazul în care aveți un cont creat pe Edneos și doriți să ștergeți atât contul cât și datele personale și alte date legate de cont, folosiți formularul pentru a începe acest proces. Vă vom trimite un cod de verificare pe emailul care poate fi asociat cu contul dvs., iar după ce introduceți acest token, veți putea finaliza procesul.`
      },  
   25:{
         en:`Sending request...`,
         ro:`Se trimite cererea ...`
      },  
   26:{
         en:`Contact us if you need assistance at `,
         ro:`Contactați-ne dacă aveți nevoie de ajutor la `
      },
   27:{
         en:`Login`,
         ro:`Autentificare`
      },
   28:{
         en:`hello@gmail.com`,
         ro:`hello@gmail.com`
      },
   29:{
         en:``,
         ro:``
      },
   30:{
         en:``,
         ro:``
      }, 
   31:{
         en:`Home`,
         ro:`Acasă`
      },               
   };

   
   
   export default generalTextTranslations;
   

   
   