// import { Fragment } from 'react';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
import { scrollToSection } from '../../../../UTILS/scrollToSection';
import classes from './NavigationMenu.module.scss';
import NavigationMenu1Items from './NavigationMenu1Items';

function NavigationMenu1 (props) {
    // const text = useGetTranslatedText('general_TextTranslations.js');  
    // const onClick_Handler = (section) => {
    //     scrollToSection(section);
    //     props.onClose();
    // }
    return (
        <ul className={classes.ulMainNav} > 
            <NavigationMenu1Items className={props.className} onClose={()=>props.onClose}/>
        </ul> 
    );
};

export default NavigationMenu1;
