// import {useNavigate } from 'react-router-dom';
import classes from './NavigationMenu.module.scss';
// import NavOptions from './NavigationMenu1';
import LanguageChanger from './LanguageChanger';
import ButtonWithArrow from '../../Buttons/ButtonWithArrow';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';

function NavigationMenu2 (props) {
    const text = useGetTranslatedText('general_TextTranslations.js');
return (
    <div className={classes.navListContainer}>
        <ul className={classes.ulMainNav} > 
            {/* <NavOptions className={`${classes.liMainNav} ${classes.hideOnMobile}`} onClose={props.onClose}/> */}
            <LanguageChanger/>
            <li className={`${classes.liMainNav} ${classes.liMainNavExtra} ${classes.mainColor} `} onClick={() => window.open('https://app.edneos.com/auth/login', '_blank', 'noopener, noreferrer')}>{text[27]}</li>       
            <ButtonWithArrow className={`${classes.button} ${classes.hideOnMobile}`} onClick={() =>window.open('https://app.edneos.com/auth/get-started ', '_blank', 'noopener, noreferrer')}><span>{text[10]}</span></ButtonWithArrow>
        </ul>     
    </div>
);
};

export default NavigationMenu2;
