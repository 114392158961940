import classes from './ButtonWithIcon.module.scss';

const ButtonWithIcon = (props) => {
  const SvgIcon = props.icon;
    return (
    <div className={`${classes.button} ${props.className}`} onClick={props.onClick}>
        {SvgIcon && <SvgIcon className={`${classes.svgIcon} ${props.classNameColor}`}/>}
        <span className={`${props.classNameColor}`}>{props.text}</span>        
    </div>
  );  

};

export default ButtonWithIcon;

