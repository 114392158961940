
import Redirect from './Redirect';
import BackgroundImage from '../../COMPONENTS/UI/Background/BackgroundImage';
import backImg from '../../FILES/Backgrounds/backImgReferral.webp';
import LoadingSpinner from '../../COMPONENTS/UI/LoadingSpinner/LoadingSpinner';

function RedirectPage() {
    return (
        <BackgroundImage vCentered backgroundImage={backImg}>
            <LoadingSpinner/>
            <Redirect/>
        </BackgroundImage>
    );
};

export default RedirectPage;


