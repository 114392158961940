import React from 'react';
import ButtonX from '../Buttons/ButtonX'
import classes from './GlassCardClose.module.css';
import {useNavigate} from "react-router-dom";

function GlassCardClose (props){
    
    const navigate = useNavigate();
    
    const close_Handler = () => {
        navigate(props.navigateOnClose); 
        if (props.reload){ window.location.reload(false);}
    };

    return (
        <div className={`${classes.container} ${props.className}`} style={props.style}>
            <ButtonX onClose={close_Handler}/>
            {props.children}
        </div> 
    );
};

export default GlassCardClose;