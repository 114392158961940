import IconSVG from '../IconSVG';

function GooglePlaySvgIcon(props) {  
    
    const lineWidth=1;
    const svgWidth=23;
    const svgHeight=25;
        
    const d1="M13.1975 11.2951L16.3595 8.29118L2.22514 0.512524C2.02706 0.403528 1.80604 0.342856 1.58008 0.335449L13.1975 11.2951Z";
    const d2="M0.189736 1.10059C0.0644197 1.32242 -0.000966876 1.57307 1.24643e-05 1.82786V22.8429C-0.0010385 23.0957 0.0643895 23.3443 0.189736 23.5638L12.098 12.3512L0.189736 1.10059Z";
    const d3="M21.3376 11.0171L17.7708 9.05664L14.2988 12.3515L17.7708 15.6274L21.3376 13.6732C21.5783 13.5449 21.7796 13.3535 21.9199 13.1196C22.0603 12.8856 22.1344 12.618 22.1344 12.3452C22.1344 12.0724 22.0603 11.8047 21.9199 11.5708C21.7796 11.3369 21.5783 11.1455 21.3376 11.0171Z";
    const d4="M13.1975 13.376L1.58008 24.3673C1.80128 24.3551 2.01713 24.2947 2.21249 24.1902L16.3785 16.3736L13.1975 13.376Z";
    
    return (
        <IconSVG 
        id={"googlePlay1"}
            svgWidth={svgWidth}
            svgHeight={svgHeight}
            lineWidth={lineWidth}
            d1={d1}
            d2={d2}
            d3={d3}
            d4={d4}
            fillColor = {props.fillColor}
            className={props.className}
            style={{...props.style}}
        />
    );
};

export default GooglePlaySvgIcon;