import React from 'react';

function SvgArrow2() {
    return (
        <svg style={{marginLeft: 10}}  width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_24_79" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="2" width="12" height="12">
                <path d="M1 13L11 3M11 3H2M11 3V12" stroke="#212121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </mask>
            <g mask="url(#mask0_24_79)">
                <rect x="-4" y="-2" width="20" height="20" fill="white"/>
            </g>
        </svg>
    );
};

export default React.memo(SvgArrow2);