import "../../assets/css/style.css";
import "../../App.css";

import {ScrollTo, ScrollArea} from "react-scroll-to";
import EdNeosHome from "./sections/EdNeosHome/EdNeosHome";
import ForThoseWhoStudy from './sections/ForThoseWhoStudy';
import ForThoseWhoTeach from './sections/ForThoseWhoTeach';
import Contact from './sections/Contact/Contact';
import Benefits from "./sections/Benefits/Benefits";
import Testimonials from "./sections/Testimonials/Testimonials";
import TryApp from "./sections/TryApp/TryApp";
import Questions from "./sections/Questions/Questions";
import Transform from "./sections/Transform/Transform";
import End from "./sections/End/End";
import Header from "../../COMPONENTS/UI/Header/Header";
import Footer from '../../COMPONENTS/UI/Footer/Footer';

function HomePage() {
    

    return (        
        <ScrollTo>
            {({scroll}) => (
                <ScrollArea>
                    <div > 
                      <Header page={'home'}/>  
                        <EdNeosHome id={'why_edneos'}/> 
                        <Benefits />
                        <ForThoseWhoStudy id={'products'}/>
                        <ForThoseWhoTeach id={'solution'}/>
                        <Testimonials />
                        <Questions id={'our_vision'}/>
                        <Transform />
                        <TryApp id={'pricing'}/>
                        <Contact id={'contact'}/>
                        <End/>
                        <Footer/>
                    </div>
                </ScrollArea>
            )}
        </ScrollTo>
    );
}

export default HomePage;
