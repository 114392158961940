
const linksTextTranslations= {
   0: {
         en:`terms-and-conditions`, 
         ro:`termeni-si-conditii`
      },
   1: {
         en:`privacy-policy`,
         ro:`politica-de-confidentialitate`
      },
   2: {
         en:`delete-account-form`,
         ro:`formular-stergere-cont`
      },
   3: {
         en:``,
         ro:``
      },
      
};
   
   export default linksTextTranslations;
   

   
   