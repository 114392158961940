import { Fragment } from 'react';

function PIS (props){
    //Paragraph Indent Space after
    return (
        <Fragment>
            <p style={{textIndent: '5vw'}}>{props.t}</p>
            <p>&nbsp;</p>
        </Fragment>
    );
};

export default PIS;