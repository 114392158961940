const generalTextTranslations= {
    0: {
         en:``,
         ro:``
      },
    1: {
         en:`Effective Date: 01.12.2023`,
         ro:`Data efectivă: 01.12.2023`
      },
    2: {
         en:`Welcome to EdNeos!`,
         ro:`Bine ați venit la EdNeos!`
      },
    3: {
         en:`Introduction:`,
         ro:`Introducere:`
      },
    4: {
         en:`EdNeos is an innovative AI educational partner, designed to support your learning journey. Whether you are a student or a pupil, EdNeos offers an interactive learning environment, using the latest findings in neuroscience and technology to help you assimilate and retain knowledge more efficiently. By using EdNeos, you will have access to personalized educational resources, adaptive learning tools, and constant support to improve your academic performance.`,
         ro:`EdNeos este un partener educațional AI inovator, conceput să vă sprijine în călătoria dumneavoastră de învățare. Fie că sunteți elev sau student, EdNeos oferă un mediu interactiv de învățare, utilizând ultimele descoperiri în neuroștiință și tehnologie pentru a vă ajuta să asimilați și să rețineți cunoștințele mai eficient. Prin utilizarea EdNeos, veți avea acces la resurse educaționale personalizate, instrumente de învățare adaptative și un suport constant pentru a vă îmbunătăți performanțele academice.`
      },
    5: {
         en:`What follows:`,
         ro:`Ce urmează:`
      },
    6: {
         en:`Before starting to use EdNeos, please read our terms and conditions carefully. They will guide you in the responsible and efficient use of our platform and inform you about your rights and responsibilities as a user.`,
         ro:`Înainte de a începe să utilizați EdNeos, vă rugăm să citiți cu atenție termenii și condițiile noastre. Acestea vă vor ghida în utilizarea responsabilă și eficientă a platformei noastre și vă vor informa despre drepturile și responsabilitățile dvs. ca utilizator.`
      },
    7: {
         en:`1. General aspects:`,
         ro:`1. Aspecte generale:`
      },
    8: {
         en:`1.1. Acceptance of Terms:`,
         ro:`1.1. Acceptarea Termenilor:`
      },
    9: {
         en:`By using EdNeos services and platform, you accept these terms, including our acceptable use policy and any service plans. These terms create a legal agreement between you and EdNeos.`,
         ro:`Prin utilizarea serviciilor și platformei EdNeos, acceptați acești termeni, inclusiv politica noastră de utilizare acceptabilă și orice planuri de servicii. Acești termeni creează un acord legal între dvs. și EdNeos.`
      },
    10: {
         en:`1.2. Conditions of Use:`,
         ro:`1. 2. Condiții de Utilizare:`
      },
    11: {
         en:`EdNeos services are available under certain conditions. If you do not agree with these conditions, you should not use the EdNeos services.`,
         ro:`Serviciile EdNeos sunt disponibile sub anumite condiții. Dacă nu sunteți de acord cu aceste condiții, nu trebuie să folosiți serviciile EdNeos.`
      },
    12: {
         en:`1.3. Privacy and Cookie Policy:`,
         ro:`1.3. Politica de Confidențialitate și Cookie-uri:`
      },
    13: {
         en:`Your personal information is treated according to our Privacy Policy and Cookie Policy. Please read them to understand how your data is managed.`,
         ro:`Informațiile dvs. personale sunt tratate conform Politicii noastre de Confidențialitate și Politicii de Cookie-uri. Vă rugăm să le citiți pentru a înțelege cum sunt gestionate datele dvs.`
      },
    14: {
         en:`1.4. Responsible Use:`,
         ro:`1.4. Utilizare Responsabilă:`
      },
    15: {
         en:`● EdNeos services should only be used for permitted purposes and in accordance with applicable laws.`,
         ro:`● Serviciile EdNeos trebuie folosite numai pentru scopurile permise și în conformitate cu legile aplicabile.`
      },
    16: {
         en:`● You are responsible for the security of your account and any activity under it.`,
         ro:`● Sunteți responsabil pentru securitatea contului dvs. și pentru orice activitate sub acesta.`
      },
    17: {
         en:`● You must immediately report any suspicious or unauthorized use of your account.`,
         ro:`● Trebuie să raportați imediat orice utilizare suspectă sau neautorizată a contului dvs.`
      },
    18: {
         en:`● You guarantee that all information you provide is accurate and current.`,
         ro:`● Garantați că toate informațiile pe care le furnizați sunt corecte și actuale.`
      },
    19: {
         en:`1.5. User Responsibility:`,
         ro:`1.5. Responsabilitatea Utilizatorului:`
      },
    20: {
         en:`EdNeos is not responsible for the accuracy of information provided by users or for verifying their identity.`,
         ro:`EdNeos nu este responsabil pentru acuratețea informațiilor furnizate de utilizatori sau pentru verificarea identității acestora.`
      },
    21: {
         en:`1.6. Legal Actions:`,
         ro:`1.6. Acțiuni Legale:`
      },
    22: {
         en:`EdNeos reserves the right to take legal action against those who misrepresent information or falsify their identity.`,
         ro:`EdNeos își rezervă dreptul de a întreprinde acțiuni legale împotriva celor care denaturează informații sau își falsifică identitatea.`
      },
    23: {
         en:`1.7 Intellectual Property:`,
         ro:`1.7 Proprietate intelectuală:`
      },
    24: {
         en:`All intellectual property rights over the application and its content, including but not limited to copyrights, trademarks, and trade secrets, are owned and licensed by EdNeos. You agree not to copy, modify, distribute, sell, or exploit any part of the application without our prior written consent.`,
         ro:`Toate drepturile de proprietate intelectuală asupra aplicației și conținutului său, inclusiv, dar fără a se limita la drepturile de autor, mărci comerciale și secrete comerciale, sunt deținute și licențiate de către EdNeos. Sunteți de acord să nu copiați, modificați, distribuiți, vindeți sau exploatați nicio parte a aplicației fără acordul nostru scris în prealabil.`
      },
    25: {
         en:``,
         ro:``
      },
    26: {
         en:``,
         ro:``
      },
    27: {
         en:`2. Service Plans Available at EdNeos`,
         ro:`2. Planuri de Servicii Disponibile la EdNeos`
      },
    28: {
         en:`EdNeos offers a variety of service plans to meet the diverse needs of our users. Detailed information about these plans can be found in the subscription section of the application settings or on our website. Our plans are classified as follows:`,
         ro:`EdNeos oferă o gamă variată de planuri de servicii pentru a răspunde nevoilor diverse ale utilizatorilor noștri. Informații detaliate despre aceste planuri pot fi găsite în aplicație la rubirca abonamente din setări sau pe site-ul nostru web . Planurile noastre sunt clasificate astfel:`
      },
    29: {
         en:`2.1. Free Service Plans:`,
         ro:`2.1. Planuri de Servicii Gratuite:`
      },
    30: {
         en:`These plans provide access to basic features of the EdNeos platform and are intended for non-commercial use. They are ideal for students and pupils who want to explore the basic functionalities of the platform.`,
         ro:`Aceste planuri oferă acces la funcții de bază ale platformei EdNeos și sunt destinate utilizării non-comerciale. Sunt ideale pentru elevi și studenți care doresc să exploreze funcționalitățile de bază ale platformei.`
      },
    31: {
         en:`2.2. Paid Service Plans:`,
         ro:`2.2. Planuri de Servicii Plătite:`
      },
    32: {
         en:`Paid plans allow access to advanced features and additional resources. These are suitable for professional and commercial use, including in educational, training, and research environments. The features and limitations of each plan can vary with some separate functionalities, and the user chooses the service plan (Pro or Premium subscription) according to their personal preferences and needs.`,
         ro:`Planurile plătite permit accesul la funcții avansate și resurse suplimentare. Acestea sunt potrivite pentru utilizare profesională și comercială, inclusiv în medii educaționale, de formare și cercetare. Caracteristicile și limitările fiecărui plan pot varia cu unele funcționalități separate iar utilizatorul alege planul de servicii ( abonamentul Pro sau Premium ) după preferințele și necesitățile personale.`
      },
    33: {
         en:`2.3. Managing Plans and Subscriptions:`,
         ro:`2.3. Gestionarea Planurilor și Abonamentelor:`
      },
    34: {
         en:`You can manage service plans and content subscriptions for purchases made through external platforms (e.g., Google Play, Apple App Store); management is done through these platforms.`,
         ro:`Puteți gestiona planurile de servicii și abonamentele de conținut  pentru achiziții efectuate prin platforme externe (ex. Google Play, Apple App Store), gestionarea se face prin intermediul acestor platforme.`
      },
    35: {
         en:`2.4. Payments, Cancellations, and Upgrades:`,
         ro:`2.4. Plăți, Anulări, și Upgrade-uri:`
      },
    36: {
         en:`● Paid service plans and content subscriptions require upfront payment and are generally non-refundable, with exceptions according to applicable legislation.`,
         ro:`● Planurile de servicii plătite și abonamentele de conținut necesită plată în avans și sunt, în general, nerambursabile, cu excepții conform legislației aplicabile.`
      },
    37: {
         en:`● At the end of each subscription period, plans and subscriptions automatically renew, and payment will be made according to the recorded payment details.`,
         ro:`● La sfârșitul fiecărei perioade de abonament, planurile și abonamentele se reînnoiesc automat, iar plata va fi efectuată conform detaliilor de plată înregistrate.`
      },
    38: {
         en:`● Cancellations can be made before the end of the current subscription period, with effect at the end of that period.`,
         ro:`● Anulările pot fi efectuate înainte de sfârșitul perioadei de abonament curente, cu efect la finalul perioadei respective.`
      },
    39: {
         en:`2.5. Flexibility of Service Plans:`,
         ro:`2.5. Flexibilitatea Planurilor de Servicii:`
      },
    40: {
         en:`● At EdNeos, you can upgrade or downgrade at any time during your subscription to another paid service plan.`,
         ro:`● La EdNeos, puteți face upgrade sau downgrade la orice moment al abonamentului dvs. la un alt plan de servicii plătite.`
      },
    41: {
         en:`● Upon upgrading, the change will take effect immediately, subject to the payment of the upgrade fee.`,
         ro:`● La upgrade, schimbarea va intra în vigoare imediat, cu condiția plății taxei de upgrade.`
      },
    42: {
         en:`● Upon downgrading, the change will take place at the end of the current subscription period by canceling a subscription and procuring the selected one.`,
         ro:`● La downgrade, modificarea va avea loc la sfârșitul perioadei curente de abonament prin anularea unui abonament și procurarea celui selectat.`
      },
    43: {
         en:`2.5. Price Changes and New Service Plans:`,
         ro:`2.5. Modificări de Preț și Noi Planuri de Servicii:`
      },
    44: {
         en:`● EdNeos reserves the right to change prices for service plans and content subscriptions. Any price change will be effective at the beginning of the next subscription period.`,
         ro:`● EdNeos își rezervă dreptul de a modifica prețurile pentru planurile de servicii și abonamentele de conținut. Orice schimbare de preț va fi efectivă la începutul următoarei perioade de abonament.`
      },
    45: {
         en:`● We will inform you accordingly about any price change, and you have the option to cancel the service if you do not agree with the new rates.`,
         ro:`● Vă vom informa în mod corespunzător despre orice modificare a prețului și aveți opțiunea de a anula serviciul în cazul în care nu sunteți de acord cu noile tarife.`
      },
    46: {
         en:`2.6. Taxes:`,
         ro:`2.6. Impozite:`
      },
    47: {
         en:`● Displayed prices may exclude applicable taxes, which will be calculated and added at the end of the transaction.`,
         ro:`● Prețurile afișate pot exclude taxele aplicabile, care vor fi calculate și adăugate la finalizarea tranzacției.`
      },
    48: {
         en:``,
         ro:``
      },
    49: {
         en:``,
         ro:``
      },
    50: {
         en:`3. User Content:`,
         ro:`3. Conținutul Utilizatorului:`
      },
    51: {
         en:`3.1. User Actions:`,
         ro:`3.1. Acțiunile utilizatorului:`
      },
    52: {
         en:`3.1. By using the application, you may submit or upload various types of content, such as sets of flashcards, images, or other materials ("User Content"). You retain all property rights over your User Content.`,
         ro:`Prin utilizarea aplicației, puteți trimite sau încărca anumite tipuri de conținut, precum seturi de cărți cu întrebări și răspunsuri, imagini sau alte materiale ("Conținutul utilizatorului"). D-voastră aveți toate drepturile de proprietate asupra conținutului utilizatorului.`
      },
    53: {
         en:`3.2. Privacy and Use of Content:`,
         ro:`3.2. Confidențialitatea și Utilizarea Conținutului:`
      },
    54: {
         en:`Content you upload to EdNeos is accessible only to you, and if you decide to provide it to someone else, you do so at your own risk, and it may be used by EdNeos for the purpose of providing our services.`,
         ro:`Conținutul pe care îl încărcați în EdNeos este accesibil doar pentru dumneavoastră iar dacă decideți să-l furnizați unei persoane, o faceți pe propria răspundere și poate fi utilizat de EdNeos în scopul furnizării serviciilor noastre.`
      },
    55: {
         en:`3.3 License Granted to EdNeos:`,
         ro:`3.3 Licența Acordată EdNeos:`
      },
    56: {
         en:`By using EdNeos, you grant a limited, worldwide, non-exclusive license for EdNeos to access, use, and process your content for reasonable purposes, such as providing and improving services, addressing technical issues, and legal compliance.`,
         ro:`Prin utilizarea EdNeos, îi acordați o licență limitată, mondială și neexclusivă pentru a accesa, utiliza și procesa conținutul dvs. în scopuri rezonabile, cum ar fi furnizarea și îmbunătățirea serviciilor, adresarea problemelor tehnice și conformitatea legală.`
      },
    57: {
         en:`EdNeos will remove your content upon account termination, except for public content, which is managed according to our privacy policy.`,
         ro:`EdNeos va elimina conținutul dvs. la rezilierea contului, cu excepția conținutului public, care este gestionat conform politicii noastre de confidențialitate.`
      },
    58: {
         en:`3.4. Your Responsibility for Content:`,
         ro:`3.4. Responsabilitatea dvs. pentru Conținut:`
      },
    59: {
         en:`You are responsible for the content you upload to EdNeos and must have all necessary rights for it. EdNeos does not endorse the accuracy or opinions expressed in User Content.`,
         ro:`Sunteți responsabil pentru conținutul pe care îl încărcați pe EdNeos și trebuie să dețineți toate drepturile necesare pentru acesta.EdNeos nu susține nici corectitudinea, nici opiniile exprimate în conținutul utilizatorului.`
      },
    60: {
         en:`3.5. Monitoring User Content:`,
         ro:`3.5. Monitorizarea Conținutului Utilizatorului:`
      },
    61: {
         en:`EdNeos may review, monitor, and, at our discretion, remove any content that violates our terms and conditions.`,
         ro:`EdNeos poate revizui, monitoriza și, la discreția noastră, elimina orice conținut care încalcă termenii și condițiile noastre.`
      },
    62: {
         en:`3.6. Public User Content:`,
         ro:`3.6. Conținut Public de Utilizator:`
      },
    63: {
         en:`If you choose to make your content public, you grant EdNeos a broad license to use and distribute this content in various forms and media.`,
         ro:`Dacă alegeți să faceți public conținutul dvs., acordați EdNeos o licență largă pentru a utiliza și distribui acest conținut în diverse forme și medii.`
      },
    64: {
         en:`3.7. Verified Creators on the EdNeos Platform:`,
         ro:`3.7. Creatori Verificați pe Platforma EdNeos:`
      },
    65: {
         en:`The EdNeos platform allows verified creators to offer educational content and resources to EdNeos users for free or depending on the type of subscription. We will ask for your consent each time you wish to receive this content.`,
         ro:`Platforma EdNeos permite creatorilor verificați să ofere  gratuit sau în dependență de tipul abonamentului, conținut educativ și resurse către utilizatorii EdNeos. Vă vom cere acceptul de fiecare dată dacă doriți să primiți acest conținut.`
      },
    66: {
         en:``,
         ro:``
      },
    67: {
         en:``,
         ro:``
      },
    68: {
         en:`4. Account Security at EdNeos`,
         ro:`4. Securitatea Contului la EdNeos`
      },
    69: {
         en:`4.1. Your Responsibility for Account Security:`,
         ro:`4.1. Responsabilitatea Dvs. pentru Securitatea Contului:`
      },
    70: {
         en:`● As an EdNeos user, you are fully responsible for the access and control of your account and the devices used to access the services.`,
         ro:`● Ca utilizator al EdNeos, aveți responsabilitatea totală pentru accesul și controlul contului dvs. și al dispozitivelor folosite pentru accesarea serviciilor.`
      },
    71: {
         en:`● It is essential to maintain the security of your devices and not to disclose passwords or payment details associated with your account.`,
         ro:`● Este esențial să mențineți securitatea dispozitivelor dvs. și să nu dezvăluiți parolele sau detaliile de plată asociate contului dvs.`
      },
    72: {
         en:`● You are responsible for updating and maintaining the accuracy of your account information.`,
         ro:`● Sunteți responsabil pentru actualizarea și menținerea acurateței informațiilor legate de contul dvs.`
      },
    73: {
         en:`● In case of unauthorized or fraudulent use, EdNeos reserves the right to suspend or close the account for the protection of all parties involved.`,
         ro:`● În caz de utilizare neautorizată sau frauduloasă, EdNeos își rezervă dreptul de a suspenda sau închide contul pentru protecția tuturor părților implicate.`
      },
    74: {
         en:`4.2. Data Protection:`,
         ro:`4.2. Protejarea Datelor:`
      },
    75: {
         en:`EdNeos commits to protecting and processing personal data in accordance with applicable legislation and our privacy policies. EdNeos will only require a short name and your email. EdNeos will not collect other information, nor will it ask you to provide other information.`,
         ro:`EdNeos se angajează să protejeze și să proceseze datele personale în conformitate cu legislația aplicabilă și politicile noastre de confidențialitate. Edneos nu va cere  alte date decât un nume scurt și emailul dvs. Edneos nu va colecta alte informații, nici nu va cere să oferiți alte informații.`
      },
    76: {
         en:`4.3. Term and Termination:`,
         ro:`4.3. Termenul și Rezilierea:`
      },
    77: {
         en:`● The agreement between you and EdNeos remains valid as long as you use our services.`,
         ro:`● Acordul dintre dvs. și EdNeos rămâne valabil atât timp cât utilizați serviciile noastre.`
      },
    78: {
         en:`● EdNeos may terminate the agreement or suspend access to our services in the case of unauthorized use or non-compliance with the terms of the agreement.`,
         ro:`● EdNeos poate rezilia acordul sau poate suspenda accesul la serviciile noastre în cazul utilizării neautorizate sau nerespectării termenilor acordului.`
      },
    79: {
         en:`● In case of termination, EdNeos will not be liable to you and will not refund any amounts already paid.`,
         ro:`● În caz de reziliere, EdNeos nu va avea nicio răspundere față de dvs. și nu va rambursa sumele deja plătite.`
      },
    80: {
         en:`4.4 Warranty and Disclaimer:`,
         ro:`4.4 Garanție și Declinarea Răspunderii:`
      },
    81: {
         en:`● EdNeos will make reasonable efforts to ensure that services function as described on our website.`,
         ro:`● EdNeos va depune eforturi rezonabile pentru ca serviciile să funcționeze conform descrierilor disponibile pe site-ul nostru web.`
      },
    82: {
         en:`● We do not guarantee that the services will be continuously available or uninterrupted.`,
         ro:`● Nu garantăm că serviciile vor fi disponibile în mod continuu sau fără întreruperi.`
      },
    83: {
         en:`● EdNeos is not responsible for problems caused by hardware, software, or external components to our services.`,
         ro:`● EdNeos nu este responsabil pentru problemele cauzate de hardware, software sau componente externe serviciilor noastre.`
      },
    84: {
         en:``,
         ro:``
      },
    85: {
         en:``,
         ro:``
      },
    86: {
         en:`5. Limitation of Liability of EdNeos`,
         ro:`5. Limitarea Răspunderii EdNeos`
      },
    87: {
         en:`5.1. Scope of Responsibility:`,
         ro:`5.1. Extinderea Responsabilității:`
      },
    88: {
         en:`In accordance with applicable law, EdNeos and its affiliates will not be liable for special, indirect, incidental, punitive, exemplary, or consequential damages, including data loss, business, or profits. The total liability of EdNeos in connection with this agreement is limited to the amount you have paid to EdNeos in the last 12 months.`,
         ro:`În conformitate cu legislația aplicabilă, EdNeos și afiliații săi nu vor fi responsabili pentru daune speciale, indirecte, incidentale, punitive, exemplare sau consecutive, inclusiv pentru pierderea de date, afaceri sau profituri. Responsabilitatea totală a EdNeos în legătură cu acest acord este limitată la suma plătită de dvs. către EdNeos în ultimele 12 luni.`
      },
    89: {
         en:`5.2. Exceptions to Limitation of Liability:`,
         ro:`5.2. Excepții la Limitarea Răspunderii:`
      },
    90: {
         en:`This limitation does not exclude EdNeos's responsibility for fraud, fraudulent statements, death or bodily injury due to negligence, or other serious cases provided by law.`,
         ro:`● Această limitare nu exclude responsabilitatea EdNeos pentru fraudă, declarații frauduloase, deces sau vătămare corporală datorată neglijenței sau alte cazuri grave prevăzute de lege.`
      },
    91: {
         en:`5.3. Specific Apple Terms for iOS Users:`,
         ro:`5.3. Termeni Specifici Apple pentru Utilizatori iOS:`
      },
    92: {
         en:`When using the EdNeos application on iOS devices, it is subject to Apple's terms and conditions, including limited rights to use the application on Apple devices.`,
         ro:`● În cazul utilizării aplicației EdNeos pe dispozitive iOS, aceasta este supusă termenilor și condițiilor Apple, inclusiv drepturile limitate de utilizare a aplicației pe dispozitivele Apple.`
      },
    93: {
         en:`5.4. Specific Apple Terms for iOS Users of the EdNeos Application:`,
         ro:`5.4. Termeni Specifici Apple pentru Utilizatori iOS ai Aplicației EdNeos`
      },
    94: {
         en:`● Apple Inc. is not a party to these Terms and does not own and is not responsible for the EdNeos application.`,
         ro:`● Apple Inc. nu este parte la acești Termeni și nu deține și nu este responsabilă pentru aplicația EdNeos.`
      },
    95: {
         en:`● Apple does not provide warranties for the EdNeos application, except for the possibility of refunding the purchase price, if applicable.`,
         ro:`● Apple nu oferă garanții pentru aplicația EdNeos, cu excepția posibilității de rambursare a prețului de achiziție, dacă este cazul.`
      },
    96: {
         en:`● Apple is not responsible for the maintenance or other support services for the EdNeos application and will not be liable for any claims, losses, liabilities, damages, costs, or expenses related to the application.`,
         ro:`● Apple nu este responsabilă pentru întreținerea sau alte servicii de asistență pentru aplicația EdNeos și nu va fi răspunzătoare pentru orice reclamații, pierderi, răspunderi, daune, costuri sau cheltuieli legate de aplicație.`
      },
    97: {
         en:`● Any questions or complaints related to the use of the EdNeos application, including those concerning intellectual property rights, should be directed to EdNeos.`,
         ro:`● Orice întrebări sau plângeri legate de utilizarea aplicației EdNeos, inclusiv cele referitoare la drepturile de proprietate intelectuală, trebuie să fie îndreptate către EdNeos.`
      },
    98: {
         en:`● The license granted to you by these Terms is limited to the use of the EdNeos application on an Apple device running iOS, owned or controlled by you, in accordance with the Usage Rules set in the Apple App Store Terms and Conditions.`,
         ro:`● Licența acordată dvs. prin acești Termeni este limitată la utilizarea aplicației EdNeos pe un dispozitiv Apple care rulează iOS, deținut sau controlat de dvs., în conformitate cu Regulile de utilizare stabilite în Termenii și Condițiile App Store Apple.`
      },
    99: {
         en:``,
         ro:``
      },
    100: {
         en:``,
         ro:``
      },
    101: {
         en:`6. Miscellaneous:`,
         ro:`6. Diverse:`
      },
    102: {
         en:`This agreement represents all the terms agreed upon between you and EdNeos and supersedes any previous agreements related to its subject.`,
         ro:`Acest acord reprezintă toți termenii conveniți între dvs. și EdNeos și înlocuiește orice acorduri anterioare legate de subiectul acestuia.`
      },
    103: {
         en:`6.1. Communication Opportunities:`,
         ro:`6.1. Posibilități de comunicare:`
      },
    104: {
         en:`Our technical team works daily to ensure the best accuracy, completeness, and reliability for all users, and we encourage you to notify us of any issues you may encounter while using the EDNEOS application or web platform at`,
         ro:`Echipa noastră tehnică lucrează zilnic pentru a asigura cea mai bună precizie, completitudine și fiabilitate pentru toți utilizatorii, și vă încurajăm să ne anunțați orice probleme pe care le-ați putea întâmpina în timp ce utilizați aplicația sau platforma web EDNEOS la adresa`
      },
    105: {
         en:`hello@edneos.com.`,
         ro:`hello@edneos.com.`
      },
    106: {
         en:`6.2. Changes to the Terms:`,
         ro:`6.2. Modificări ale Termenilor:`
      },
    107: {
         en:`EdNeos reserves the right to modify the terms of this agreement. Any changes will be communicated accordingly.`,
         ro:`EdNeos își rezervă dreptul de a modifica termenii acestui acord. Orice modificare va fi comunicată în mod corespunzător.`
      },
    108: {
         en:`6.3. Applicable Law and Jurisdiction:`,
         ro:`6.3. Legea Aplicabilă și Jurisdicția:`
      },
    109: {
         en:`● This agreement is governed by the laws of Romania. Any dispute will be resolved in the courts of Romania.`,
         ro:`● Acest acord este guvernat de legile României. Orice litigiu va fi soluționat în instanțele din România.`
      },
    110: {
         en:``,
         ro:``
      },
    111: {
         en:``,
         ro:``
      },
    112: {
         en:`7. EdNeos Definitions`,
         ro:`7. Definiții EdNeos`
      },
    113: {
         en:`"Acceptable Use Policy" means the EdNeos acceptable use policy, which is updated periodically and available on our website.`,
         ro:`„Politica de Utilizare Acceptabilă” înseamnă politica EdNeos de utilizare acceptabilă, care este actualizată periodic și disponibilă pe site-ul nostru web.`
      },
    114: {
         en:`"Administrator" means the person designated by your organization to manage the EdNeos accounts of that organization, if applicable.`,
         ro:`„Administrator” înseamnă persoana desemnată de organizația dvs. pentru a gestiona conturile EdNeos ale acelei organizații, dacă este cazul.`
      },
    115: {
         en:`"Agreement" represents these Terms, the Acceptable Use Policy, any chosen Service Plan, and, if applicable, any Enterprise Agreement.`,
         ro:`„Acord” reprezintă acești Termeni, Politica de Utilizare Acceptabilă, orice Plan de Servicii ales și, dacă este aplicabil, orice Acord de Întreprindere.`
      },
    116: {
         en:`"Application" means the mobile applications and software of EdNeos.`,
         ro:`„Aplicație” înseamnă aplicațiile mobile și software-ul EdNeos.`
      },
    117: {
         en:`"Apple" refers to Apple Inc., in the context of using EdNeos applications on iOS devices.`,
         ro:`„Apple” înseamnă Apple Inc., în contextul utilizării aplicațiilor EdNeos pe dispozitive iOS.`
      },
    118: {
         en:`"Children" means minors under the age of 13 in the USA or under the age of 16 in other jurisdictions.`,
         ro:`„Copii” înseamnă minorii sub vârsta de 13 ani din SUA sau sub vârsta de 16 ani în alte jurisdicții.`
      },
    119: {
         en:`"Code" refers to codes or other promotional offers provided by EdNeos for access to paid service plans or content subscriptions.`,
         ro:`„Cod” se referă la coduri sau alte oferte promoționale furnizate de EdNeos pentru acces la planuri de servicii plătite sau abonamente de conținut.`
      },
    120: {
         en:`"Consumer" means an individual who uses EdNeos services for non-commercial purposes.`,
         ro:`„Consumator” înseamnă o persoană fizică care utilizează serviciile EdNeos fără scopuri comerciale.`
      },
    121: {
         en:`"Content Subscriptions" represent subscriptions to premium educational content libraries offered by EdNeos, in addition to the chosen Service Plan.`,
         ro:`„Abonamente la Conținut” reprezintă abonamentele la biblioteci de conținut educațional premium oferite de EdNeos, în plus față de Planul de Servicii ales.`
      },
    122: {
         en:`"Enterprise Agreement" refers to a separate contract between EdNeos and your associated organization.`,
         ro:`„Acord de Întreprindere” se referă la un contract separat între EdNeos și organizația dvs. asociată.`
      },
    123: {
         en:`"EdNeos" is the name of the company that provides the EdNeos services.`,
         ro:`„EdNeos” este denumirea companiei care furnizează serviciile EdNeos.`
      },
    124: {
         en:`"EdNeos Brands" refers to all trademarks, service marks, trade names, logos, and any other distinctive brand features of EdNeos.`,
         ro:`„EdNeos Brands” se referă la toate mărcile comerciale, mărcile de servicii, nume comerciale, logo-uri și orice alte caracteristici distinctive ale mărcii EdNeos.`
      },
    125: {
         en:`"License" means the right of use granted according to section 3 of these Terms.`,
         ro:`„Licență” înseamnă dreptul de utilizare acordat conform secțiunii 3 a acestor Termeni.`
      },
    126: {
         en:`"Platform" means the websites and applications of EdNeos where services are available.`,
         ro:`„Platformă” înseamnă site-urile web și aplicațiile EdNeos unde serviciile sunt disponibile.`
      },
    127: {
         en:`"Public User Content" refers to content created by users and made available to the public at large through the EdNeos services.`,
         ro:`„Conținut Public de Utilizator” se referă la conținutul creat de utilizatori și făcut disponibil publicului larg prin serviciile EdNeos.`
      },
    128: {
         en:`"Resources" means the information, services, products, and tools offered through EdNeos.`,
         ro:`„Resurse” înseamnă informațiile, serviciile, produsele și instrumentele oferite prin EdNeos.`
      },
    129: {
         en:`"Resources" means the information, services, products, and tools offered through EdNeos.`,
         ro:`„Plan de Servicii” reprezintă diferitele planuri de abonament pentru serviciile EdNeos.`
      },
    130: {
         en:`"Services" means the educational services and other services offered through EdNeos.`,
         ro:`„Servicii” înseamnă serviciile educaționale și alte servicii oferite prin EdNeos.`
      },
    131: {
         en:`"Subscription Period" means the duration for which a Service Plan or Content Subscription is active.`,
         ro:`„Perioada de Abonament” înseamnă durata pentru care un Plan de Servicii sau un Abonament de Conținut este activ.`
      },
    132: {
         en:`"Terms" refer to the general terms and conditions of using EdNeos services.`,
         ro:`„Termeni” se referă la termenii și condițiile generale ale utilizării serviciilor EdNeos.`
      },
    133: {
         en:`"Third-Party Applications" means services and software provided by third parties, which are integrated into EdNeos services.`,
         ro:`„Aplicații ale Terților” înseamnă servicii și software-uri oferite de terți, care sunt integrate în serviciile EdNeos.`
      },
    134: {
         en:`"Trial" represents trial periods for paid Service Plans or Content Subscriptions.`,
         ro:`„Probă” reprezintă perioade de încercare pentru Planurile de Servicii plătite sau Abonamentele de Conținut.`
      },
    135: {
         en:`"User Content" means all data, content, and information uploaded by users within the EdNeos services.`,
         ro:`„Conținutul Utilizatorului” înseamnă toate datele, conținutul și informațiile încărcate de utilizatori în cadrul serviciilor EdNeos.`
      },
    136: {
         en:`"Vanity URL" represents a customized URL for a user or organization within the EdNeos platform.`,
         ro:`„Adresa URL de Vanitate” reprezintă o adresă URL personalizată pentru un utilizator sau organizație în cadrul platformei EdNeos.`
      },
    137: {
         en:`"Verified Creator" refers to an EdNeos user who has been verified according to our standards.`,
         ro:`„Creator Verificat” se referă la un utilizator EdNeos care a fost verificat conform standardelor noastre.`
      },
};
export default generalTextTranslations;