import classes from './ButtonWithImg.module.css';

const ButtonWithImg = (props) => {
  return (
    <div className={classes.buttonWithImg} style={{backgroundImage: `url(${props.imgSrc})`}} onClick={props.onClick}>
        <img src={props.imgSrc} className={classes.buttonImg} alt={'button'}/>
         {/* imgSrc > same as 'background-image' from css */}
        <div className={classes.buttonDiv}>{props.title}</div>
    </div>
  );
};

export default ButtonWithImg;
