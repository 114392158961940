import React from 'react';

import classes from './SectionCard.module.css';

function SectionCard (props){
    // console.log(props.flexDirection);
    return (
        <section className={`${classes.section} ${props.className}`} style={{backgroundColor: props.backgroundColor}} id={props.id}>
            <div className={classes.mainContainer} style={{flexDirection: props.flexDirection, textAlign:props.textAlign}}>
                {props.children}
            </div>
        </section>
    );
};

export default SectionCard;