
 
import classes from './ButtonWithArrow.module.scss';
import Arrow1SvgIcon from '../../SVG/Icons/Arrow1SvgIcon';
const ButtonWithArrow = (props) => {
  return (
//     <div className={`${classes.button} ${props.className}`} onClick={props.onClick}>
//     <Arrow1SvgIcon className={`${classes.svgIcon} ${props.classNameColor}`}/>
//     {/* <AppStoreSvgIcon className={`${classes.svgIcon} ${props.classNameColor}`}/> */}
//     <span className={`${props.classNameColor}`}>{props.children}</span>        
// </div>
    <button
      className={`${classes.button} ${props.className}`}
      style={props.style}
      type={props.type || 'button'}
      onClick={props.onClick}
    >
      <span className={`${classes.text} ${props.classNameColor}`}>{props.children}</span> 
     <Arrow1SvgIcon className={`${classes.svgIcon} ${props.classNameColor}`}/>
      
      {/* <SvgArrow2/> */}
    </button>
  );
};

export default ButtonWithArrow;
