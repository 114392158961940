
import classes from './Logo.module.scss';
import { Link } from 'react-router-dom';

function Logo (props){
  return (
    <Link to={'/'}>
        <h1 className={classes.logo}><span>Ed</span>Neos</h1>
    </Link>
  );
};

export default Logo;



