const generalTextTranslations= {

    0: {
         en:``,
         ro:``
      },
    1: {
         en:`Edneos Privacy Policy`,
         ro:`Politica de Confidențialitate Edneos`
      },
    2: {
         en:`1. About This Privacy Policy`,
         ro:`1. Despre această Politică de Confidențialitate`
      },
    3: {
         en:`Thank you for choosing the EdNeos mobile application ("the Application") and/or the EdNeos web platform ("the Web Platform"). This Privacy Policy explains how we collect, use, and protect your personal information when you use the Application/Web Platform. By using the Application/Web Platform, you agree to the practices described in this Privacy Policy. If you do not agree with the practices described below, please do not use the Application/Web Platform.`,
         ro:`Vă mulțumim că ați ales aplicația mobilă EdNeos ("Aplicația") și/sau platforma web EdNeos ("Platforma Web"). Această Politică de Confidențialitate explică modul în care colectăm, utilizăm și protejăm informațiile dvs. personale atunci când utilizați Aplicația/Platforma Web. Prin utilizarea Aplicației/Platformei Web, sunteți de acord cu practicile descrise în această Politică de Confidențialitate. Dacă nu sunteți de acord cu practicile descrise mai jos, vă rugăm să nu utilizați Aplicația/Platforma Web.`
      },
    4: {
         en:`Edneos is a global educational technology group offering an interactive learning platform. We focus on developing engaging learning tools for both the educational sector and individual users.

`,
         ro:`Edneos este un grup global de tehnologie educațională care oferă o platformă de învățare interactivă. Ne concentrăm pe dezvoltarea unor instrumente de învățare captivante, atât pentru sectorul educațional, cât și pentru utilizatorii individuali.`
      },
    5: {
         en:`1.1. Personal Information Processed by Edneos`,
         ro:`1.1. Informațiile Personale Procesate de Edneos`
      },
    6: {
         en:`We collect and process minimal personal information depending on how you interact with Edneos, your account settings, and the products you use. This information includes:`,
         ro:`Colectăm și procesăm minim informații personale în funcție de modul în care interacționați cu Edneos, setările contului și produsele pe care le utilizați. Aceste informații includ:`
      },
    7: {
         en:`● Information Provided by You: Name, email address.`,
         ro:`● Informații Furnizate de dvs.: Nume, adresă de e-mail.`
      },
    8: {
         en:`● Communication Data: Information shared through interaction with Edneos services, such as comments on the platform.`,
         ro:`● Date de Comunicare: Informații partajate prin interacțiunea cu serviciile EdNeos, cum ar fi comentariile pe platformă.`
      },
    9: {
         en:``,
         ro:``
      },
    10: {
         en:``,
         ro:``
      },
    11: {
         en:`2. Use of Personal Information`,
         ro:`2. Utilizarea Informațiilor Personale`
      },
    12: {
         en:`We use the collected information to provide and improve Edneos services, to communicate with you, and to respond to support requests.`,
         ro:`Utilizăm informațiile colectate pentru a furniza și îmbunătăți serviciile Edneos, pentru a comunica cu dvs. și pentru a răspunde solicitărilor de asistență.`
      },
    13: {
         en:`Edneos implements security measures to protect personal data against unauthorized access and illegal use.`,
         ro:`Edneos implementează măsuri de securitate pentru a proteja datele personale împotriva accesului neautorizat și a utilizării ilegale.`
      },
    14: {
         en:`You have the right to access, correct, or delete the personal information we hold about you and to restrict its processing in the Settings menu.`,
         ro:`Aveți dreptul să accesați, să rectificați sau să ștergeți informațiile personale pe care le deținem despre dvs. și să restricționați prelucrarea acestora în meniul Setări

`
      },
    15: {
         en:``,
         ro:``
      },
    16: {
         en:``,
         ro:``
      },
    17: {
         en:`3. Information We Collect Automatically`,
         ro:`3. Informații pe care le Colectăm Automat`
      },
    18: {
         en:`At EdNeos, we respect data protection legislation and consider certain information as personal according to applicable laws. We automatically collect various types of data during your use of our service, including:`,
         ro:`În cadrul EdNeos, respectăm legislația privind protecția datelor și considerăm anumite informații ca fiind personale conform legilor aplicabile. Colectăm automat diferite tipuri de date în timpul utilizării serviciului nostru, inclusiv:`
      },
    19: {
         en:`3.1. Device and Browser Information:`,
         ro:`3.1. Informații despre Dispozitiv și Browser:`
      },
    20: {
         en:`● The type of computer (Windows or Macintosh).`,
         ro:`● Tipul computerului (Windows sau Macintosh).`
      },
    21: {
         en:`● Screen resolution.`,
         ro:`● Rezoluția ecranului.`
      },
    22: {
         en:`● Name and version of the operating system.`,
         ro:`● Numele și versiunea sistemului de operare.`
      },
    23: {
         en:`● Device manufacturer and model.`,
         ro:`● Producătorul și modelul dispozitivului.`
      },
    24: {
         en:`● Language.`,
         ro:`● Limba.`
      },
    25: {
         en:`● Type and version of the internet browser.`,
         ro:`● Tipul și versiunea browserului de internet.`
      },
    26: {
         en:`● Version of the Edneos application used.`,
         ro:`● Versiunea aplicației Edneos utilizate.`
      },
    27: {
         en:`3.2. Usage Data:`,
         ro:`3.2. Date de Utilizare:`
      },
    28: {
         en:`● Browsing activities and interactions with the service (such as sections used).`,
         ro:`● Activitățile de navigare și interacțiune cu serviciul (cum ar fi secțiunile utilizate).`
      },
    29: {
         en:`● Information regarding service performance, including aspects related to email delivery and other communications.`,
         ro:`● Informații privind performanța serviciilor, inclusiv aspecte legate de livrarea e-mailurilor și alte comunicări.`
      },
    30: {
         en:`● Data on the frequency of application use and other performance data.`,
         ro:`● Date privind frecvența utilizării aplicației și alte date de performanță`
      },
    31: {
         en:`3.3. Log Data:`,
         ro:`3.3. Date de Jurnal:`
      },
    32: {
         en:`● Records of accesses on our web servers, including originating IP addresses.`,
         ro:`● Înregistrări ale accesărilor pe serverele noastre web, inclusiv adresele IP de origine.`
      },
    33: {
         en:`● Activity in services, such as timestamps, interactions with application features, and other actions taken.`,
         ro:`● Activitatea în servicii, cum ar fi ștampilele de dată/oră, interacțiunile cu caracteristicile aplicației și alte acțiuni efectuate.`
      },
    34: {
         en:`3.4. Cookies and Tracking Technologies:`,
         ro:`3.4. Cookie-uri și Tehnologii de Urmărire:`
      },
    35: {
         en:`● We collect information through cookies and similar technologies to understand how our service is used.`,
         ro:`● Colectăm informații prin cookie-uri și tehnologii similare pentru a înțelege cum este utilizat serviciul nostru.`
      },
    36: {
         en:`● Pixel tags are used to track user actions and measure the effectiveness of marketing campaigns.`,
         ro:`● Etichetele pixeli sunt folosite pentru a urmări acțiunile utilizatorilor și a măsura eficiența campaniilor de marketing.`
      },
    37: {
         en:``,
         ro:``
      },
    38: {
         en:``,
         ro:``
      },
    39: {
         en:`4. Your Data Protection Rights with EdNeos`,
         ro:`4. Drepturile Dumneavoastră de Protecție a Datelor cu EdNeos`
      },
    40: {
         en:`4.1. Know Your Rights`,
         ro:`4.1. Cunoașteți-vă Drepturile`
      },
    41: {
         en:`Depending on your jurisdiction, you may have the following data protection rights:`,
         ro:`În funcție de jurisdicția în care locuiți, puteți avea următoarele drepturi de protecție a datelor:`
      },
    42: {
         en:`● Access, correct, update, transfer, delete, restrict, or object to Edneos' processing of your personal information.`,
         ro:`● Accesați, corectați, actualizați, transferați, ștergeți, restrângeți sau vă opuneți prelucrării informațiilor personale de către Edneos.`
      },
    43: {
         en:`● Withdraw consent for processing based on consent, without affecting the lawfulness of processing carried out before withdrawal.`,
         ro:`● Retragerea consimțământului pentru prelucrarea bazată pe consimțământ, fără a afecta legalitatea prelucrării efectuate înainte de retragere.`
      },
    44: {
         en:`Exercising Your Rights`,
         ro:`Exercitarea Drepturilor`
      },
    45: {
         en:`To exercise your rights, you can contact us at hello@edneos.com. We respond to requests to exercise data protection rights in accordance with applicable law. We may ask you to verify your identity to efficiently process your request.`,
         ro:`Pentru a vă exercita drepturile, ne puteți contacta la hello@edneos.com. Vă răspundem la solicitările de exercitare a drepturilor de protecție a datelor în conformitate cu legislația aplicabilă. Vă putem solicita să vă verificați identitatea pentru a procesa cererea dvs. eficient.`
      },
    46: {
         en:`4.2. Sharing Personal Information`,
         ro:`4.2. Partajarea Informațiilor Personale`
      },
    47: {
         en:`EdNeos does not share personal information with third parties, except in the limited cases described below:`,
         ro:`EdNeos nu împărtășește informații personale cu terțe părți, cu excepția cazurilor limitate descrise mai jos:`
      },
    48: {
         en:`● Affiliates and Service Providers: May be shared with affiliated companies or service providers who perform services on our behalf, such as web hosting, technical support, payment processing, and other similar services.`,
         ro:`● Afiliați și Furnizori de Servicii: Pot fi partajate cu companii afiliate sau furnizori de servicii care prestează servicii în numele nostru, cum ar fi găzduirea web, suportul tehnic, procesarea plăților și alte servicii similare.`
      },
    49: {
         en:`● Social Network Partners: May be used in cooperation with social networks to promote EdNeos services.`,
         ro:`● Parteneri Rețele Sociale: Pot fi utilizate în cooperare cu rețelele sociale pentru promovarea serviciilor EdNeos.`
      },
    50: {
         en:`● Legal and Government Authorities: In case of legal requirements or to protect legal rights.`,
         ro:`● Autorități Legale și Guvernamentale: În cazul unor cerințe legale sau pentru protecția drepturilor legale.`
      },
    51: {
         en:`● Business Transactions: In case of a merger, sale, or other business transactions`,
         ro:`● Tranzacții Comerciale: În cazul unei fuziuni, vânzări sau alte tranzacții comerciale.`
      },
    52: {
         en:`● Other Parties with Your Consent: Other situations where you have given explicit consent.`,
         ro:`● Alte Părți cu Acordul Dumneavoastră: Alte situații în care aveți acordul explicit.`
      },
    53: {
         en:`4.3. Responsibility for Data Privacy`,
         ro:`4.3. Responsabilitatea pentru Confidențialitatea Datelor`
      },
    54: {
         en:`EdNeos is responsible for the privacy of data provided to service providers and partners, ensuring that they adhere to our data protection and security standards.`,
         ro:`EdNeos este responsabil pentru confidențialitatea datelor furnizate către furnizorii de servicii și parteneri, asigurându-se că aceștia respectă standardele noastre de protecție și securitate a datelor.`
      },
    55: {
         en:``,
         ro:``
      },
    56: {
         en:``,
         ro:``
      },
    57: {
         en:`5. Data Security at EdNeos`,
         ro:`5. Securitatea Datelor la Edneos`
      },
    58: {
         en:`EdNeos implements a comprehensive security program designed to protect the security, privacy, and integrity of personal information. We implement appropriate technical and organizational measures to protect personal data against loss, misuse, unauthorized access, disclosure, alteration, and destruction. These measures include data encryption, firewalls, access control to data and facilities, and compliance with relevant industry standards such as PCI for credit card transactions.`,
         ro:`EdNeos implementează un program cuprinzător de securitate menit să protejeze securitatea, confidențialitatea și integritatea informațiilor personale. Implementăm măsuri tehnice și organizatorice adecvate pentru a proteja datele personale împotriva pierderii, utilizării greșite, accesului neautorizat, dezvăluirii, modificării și distrugerii. Aceste măsuri includ criptarea datelor, firewall-uri, controlul accesului la date și la facilități și conformitatea cu standardele industriale relevante, cum ar fi PCI pentru tranzacții cu cardul de credit.`
      },
    59: {
         en:`5.1. International Data Transfers`,
         ro:`5.1. Transferuri Internaționale de Date`
      },
    60: {
         en:`Data collected by Edneos may be transferred, processed, and stored outside the country of residence of users, in countries where we operate or where our service providers are located. We ensure that any such transfer complies with applicable law and is based on adequate legal grounds, including standard contractual clauses approved by the European Commission.`,
         ro:`Datele colectate de Edneos pot fi transferate, procesate și stocate în afara țării de reședință a utilizatorilor, în țările unde operăm sau unde se află furnizorii noștri de servicii. Ne asigurăm că orice astfel de transfer respectă legislația aplicabilă și se face pe baza unor temeiuri juridice adecvate, inclusiv clauze contractuale standard aprobate de Comisia Europeană.`
      },
    61: {
         en:`5.2. Retention of Personal Information`,
         ro:`5.2. Păstrarea Informațiilor Personale`
      },
    62: {
         en:`Edneos does not retain personal information longer than necessary for the purposes for which it was collected. The retention duration depends on legal or contractual needs, the necessity of the data for providing our services, and reasonable user expectations. When personal data is no longer necessary, it is deleted or anonymized according to applicable law.`,
         ro:`Edneos nu păstrează informațiile personale mai mult decât este necesar pentru scopurile pentru care au fost colectate. Durata de păstrare depinde de nevoia legală sau contractuală, necesitatea datelor pentru furnizarea serviciilor noastre și așteptările rezonabile ale utilizatorilor. Atunci când nu mai este necesară păstrarea datelor personale, acestea sunt șterse sau anonimizate conform legislației aplicabile.`
      },
    63: {
         en:`5.3. Users in Certain Jurisdictions`,
         ro:`5.3. Utilizatorii din Anumite Jurisdicții`
      },
    64: {
         en:`For users in California, Colorado, Connecticut, Utah, and Virginia, EdNeos provides additional details regarding the collection, use, and disclosure of personal information, in accordance with the specific laws of these states. This includes information about the categories of personal data collected, processed, and disclosed, as well as the specific rights of consumers in these jurisdictions.`,
         ro:`Pentru utilizatorii din California, Colorado, Connecticut, Utah și Virginia, EdNeos oferă detalii suplimentare privind colectarea, utilizarea și dezvăluirea informațiilor personale, conform legilor specifice ale acestor state. Aceasta include informații despre categoriile de date personale colectate, procesate și dezvăluite, precum și drepturile specifice ale consumatorilor din aceste jurisdicții.`
      },
    65: {
         en:`5.4. Categories of Personal Information Collected and Processed by Edneos`,
         ro:`5.4. Categorii de Informații Personale Colectate și Procesate de Edneos`
      },
    66: {
         en:`● Personal Identifiers`,
         ro:`● Identificatori Personali`
      },
    67: {
         en:`Examples: Names, email addresses, cookies.`,
         ro:`Exemple: Nume, adrese de e-mail, cookie-uri.`
      },
    68: {
         en:`Purposes of Processing: Providing Edneos services, enabling use of features, communication, support and service improvement, transactions and payments, abuse prevention, legal obligations compliance, data optimization and analysis, marketing, participation in sweepstakes and promotions, aggregated and anonymized data.`,
         ro:`Scopurile de Prelucrare: Furnizarea serviciilor Edneos, permiterea utilizării funcțiilor, comunicare, suport și îmbunătățirea serviciilor, tranzacții și plăți, prevenirea abuzului, respectarea obligațiilor legale, optimizarea și analiza datelor, marketing, participarea la tombole și promoții, date agregate și anonimizate.`
      },
    69: {
         en:`● Personal Information (as defined under California's customer records law)`,
         ro:`● Informații Personale (definite conform legii privind evidențele clienților din California)`
      },
    70: {
         en:`Examples: Contact information, payment information.`,
         ro:`Exemple: Informații de contact, informații de plată.

`
      },
    71: {
         en:`Purposes of Processing: Same as for personal identifiers.`,
         ro:`Scopurile de Prelucrare: Aceleași ca la identificatori personali.`
      },
    72: {
         en:`● Protected Class Information`,
         ro:`● Informații de Clasă Protejate`
      },
    73: {
         en:`Examples: Age.`,
         ro:`Exemple: Vârstă.`
      },
    74: {
         en:`Purposes of Processing: Same as for personal identifiers.`,
         ro:`Scopurile de Prelucrare: Aceleași ca la identificatori personali.`
      },
    75: {
         en:`● Commercial Information`,
         ro:`● Informații Comerciale`
      },
    76: {
         en:`Examples: Transaction information and purchase history.`,
         ro:`Exemple: Informații despre tranzacții și istoricul achizițiilor.`
      },
    77: {
         en:`Purposes of Processing: Same as for personal identifiers.`,
         ro:`Scopurile de Prelucrare: Aceleași ca la identificatori personali.`
      },
    78: {
         en:`● Internet or Network Activity Information`,
         ro:`● Informații despre Activitatea pe Internet sau în Rețea`
      },
    79: {
         en:`Examples: Browsing history, search history, interactions with Edneos website.`,
         ro:`Exemple: Istoric de navigare, istoric de căutări, interacțiuni cu site-ul Edneos.`
      },
    80: {
         en:`Purposes of Processing: Same as for personal identifiers.`,
         ro:`Scopurile de Prelucrare: Aceleași ca la identificatori personali.`
      },
    81: {
         en:`● Geolocation Data`,
         ro:`● Datele de Geolocalizare`
      },
    82: {
         en:`Examples: City-level information derived from IP address.`,
         ro:`Exemple: Informații la nivel de oraș, derivate din adresa IP.`
      },
    83: {
         en:`Purposes of Processing: Same as for personal identifiers.`,
         ro:`Scopurile de Prelucrare: Aceleași ca la identificatori personali.`
      },
    84: {
         en:`● Audio, Electronic, Visual Information`,
         ro:`● Informații Audio, Electronice, Vizuale`
      },
    85: {
         en:`Examples: Photos uploaded by users.`,
         ro:`Exemple: Fotografii încărcate de utilizatori.`
      },
    86: {
         en:`Purposes of Processing: Providing services, support and service improvement, abuse prevention, legal obligations compliance, service optimization, data analysis.`,
         ro:`Scopurile de Prelucrare: Furnizarea serviciilor, suportul și îmbunătățirea serviciilor, prevenirea abuzului, respectarea obligațiilor legale, optimizarea serviciului, analiza datelor.`
      },
    87: {
         en:`5.5. Disclosure of Information to Third Parties`,
         ro:`5.5. Dezvăluirea Informațiilor Către Terți`
      },
    88: {
         en:`EdNeos may disclose these categories of personal information to:`,
         ro:`EdNeos poate dezvălui aceste categorii de informații personale către:`
      },
    89: {
         en:`● Affiliates and service providers for operational purposes.`,
         ro:`● Afiliați și prestatori de servicii pentru scopurile operaționale.`
      },
    90: {
         en:`● Public institutions for legal compliance and rights protection.`,
         ro:`● Instituții publice pentru conformitate legală și protecția drepturilor.`
      },
    91: {
         en:`● Social network providers for service promotion.`,
         ro:`● Furnizori de rețele sociale pentru promovarea serviciilor.`
      },
    92: {
         en:`● Potential buyers in business transactions.`,
         ro:`● Potențiali cumpărători în cazul tranzacțiilor comerciale.`
      },
    93: {
         en:`● Other third parties with the express consent of the user.`,
         ro:`● Alte terțe părți cu acordul expres al utilizatorului.`
      },
    94: {
         en:``,
         ro:``
      },
    95: {
         en:``,
         ro:``
      },
    96: {
         en:`6. Rights and Requests within EdNeos`,
         ro:`6. Drepturi și Cereri în cadrul EdNeos`
      },
    97: {
         en:`Exercising Your Rights`,
         ro:`Exercitarea Drepturilor Dumneavoastră`
      },
    98: {
         en:`6.1 In accordance with applicable law, you have the right to make the following requests to Edneos:`,
         ro:`6.1 Conform legislației aplicabile, aveți dreptul de a face următoarele solicitări la Edneos:`
      },
    99: {
         en:`● Access Personal Information: Find out if we are processing your personal information and access these details.`,
         ro:`● Acces la Informații Personale: Aflați dacă procesăm informațiile dvs. personale și accesați aceste informații.`
      },
    100: {
         en:`● Correct Inaccuracies: Rectify inaccuracies in your personal information.`,
         ro:`● Corectarea Inexactităților: Rectificați inexactitățile din informațiile dvs. personale.`
      },
    101: {
         en:`● Delete Data:\u00A0`,
         ro:`● Ștergerea Datelor:\u00A0`
      },
    102: {
         en:`● Data Portability: Receive a copy of your personal information in a portable format.`,
         ro:`● Portabilitatea Datelor: Primiți o copie a informațiilor personale într-un format portabil.`
      },
    103: {
         en:`6.2. Specific Requests for Residents in Certain Jurisdictions:`,
         ro:`6.2. Cereri Specifice pentru Rezidenți în Anumite Jurisdicții:`
      },
    104: {
         en:`● Request the categories of personal information collected, the purpose of collection, and the categories of third parties to whom we have disclosed this information.`,
         ro:`● Solicitați categoriile de informații personale colectate, scopul colectării și categoriile de terțe părți cărora le-am dezvăluit aceste informații.`
      },
    105: {
         en:`6.3. Non-Discrimination:`,
         ro:`6.3. Non-Discriminare:`
      },
    106: {
         en:`You have the right not to be discriminated against for exercising your privacy rights.`,
         ro:`Aveți dreptul de a nu fi discriminat pentru exercitarea drepturilor de confidențialitate.`
      },
    107: {
         en:`6.4. The Process of Submitting Requests`,
         ro:`6.4. Procesul de Trimitere a Cererilor`
      },
    108: {
         en:`● Contact us at hello@edneos.com to make a request.`,
         ro:`● Contactați-ne la hello@edneos.com  pentru a face o cerere.`
      },
    109: {
         en:`● We will verify and respond to your requests in accordance with applicable law.`,
         ro:`● Vom verifica și răspunde solicitărilor dumneavoastră conform legislației aplicabile.`
      },
    110: {
         en:`● For identity verification, we may request additional information such as your registered email address or recent login activity.`,
         ro:`● Pentru verificarea identității, putem solicita informații suplimentare, cum ar fi adresa de e-mail înregistrată sau activitatea recentă de conectare.`
      },
    111: {
         en:`6.5. Edneos' Role in Data Processing`,
         ro:`6.5. Rolul Edneos în Prelucrarea Datelor`
      },
    112: {
         en:`● Edneos acts as a service provider or processor, collecting personal information on behalf of our users.`,
         ro:`● Edneos acționează ca furnizor de servicii sau procesor, colectând informații personale în numele utilizatorilor noștri.`
      },
    113: {
         en:`● For requests regarding access, correction, or deletion of personal information, please contact the entity with which you have a direct relationship.`,
         ro:`● În cazul unor solicitări de acces, corectare sau ștergere a informațiilor personale, vă rugăm să contactați entitatea cu care aveți o relație directă.`
      },
    114: {
         en:`6.6. Sale of Personal Information`,
         ro:`6.6. Vânzarea Informațiilor Personale`
      },
    115: {
         en:`● Edneos does not sell personal information (including sensitive personal information) and does not engage in cross-context behavioral advertising.`,
         ro:`● Edneos nu vinde informații personale (inclusiv informații personale sensibile) și nu participă la publicitate comportamentală sau direcționată încrucișată.`
      },
    116: {
         en:`6.7. Appeal Process`,
         ro:`6.7. Procesul de Apel`
      },
    117: {
         en:`● Requests made by authorized agents will be processed in accordance with applicable law. We may request confirmation of the agent's identity and your permission granted to them.`,
         ro:`● Solicitările făcute de agenți autorizați vor fi procesate conform legislației aplicabile. Este posibil să solicităm confirmarea identității agentului și a permisiunii acordate de dvs.`
      },
    118: {
         en:`6.8. Use of De-Identified Information`,
         ro:`6.8. Utilizarea Informațiilor De-Identificate`
      },
    119: {
         en:`● Edneos maintains and uses de-identified information only in a de-identified manner and will not attempt to re-identify the information.`,
         ro:`● Edneos menține și utilizează informațiile de-identificate numai într-un mod de-identificat și nu va încerca să reidentifice informațiile.`
      },
    120: {
         en:`6.9. Do Not Track (DNT)`,
         ro:`6.9. Nu Urmăriți (Do Not Track)`
      },
    121: {
         en:`● Edneos respects "Do Not Track" settings in your browser and does not monitor or follow any Do Not Track browser requests, as per the standards set in this Privacy Policy.`,
         ro:`● Edneos respectă setările "Nu urmăriți" din browserul dvs. și nu monitorizează sau urmărește nicio solicitare de browser Do Not Track, conform standardelor stabilite în această Politică de confidențialitate.`
      },
    122: {
         en:`Request the deletion of your personal information.`,
         ro:`Solicitați ștergerea informațiilor personale.`
      },
    123: {
         en:``,
         ro:``
      },
    124: {
         en:`7. Changes to the Privacy Policy`,
         ro:`7. Modificări ale Politicii de Confidențialitate`
      },
    125: {
         en:`Edneos reserves the right to modify this Privacy Policy. Any significant changes will be communicated in advance via email or other notification means. Your continued use of Edneos services after such changes will constitute your acceptance of these changes.`,
         ro:`Edneos își rezervă dreptul de a modifica această Politică de confidențialitate. Orice modificări semnificative vor fi comunicate în avans prin e-mail sau prin alte mijloace de notificare. Continuarea utilizării serviciilor Edneos după astfel de modificări va constitui acceptul dumneavoastră al acestor schimbări.`
      },
    126: {
         en:``,
         ro:``
      },
    127: {
         en:``,
         ro:``
      },
    128: {
         en:`8. Collection of Personal Information from Children`,
         ro:`8. Colectarea Informațiilor Personale de la Copii`
      },
    129: {
         en:`EdNeos does not collect personal information from children for commercial purposes. The application is more suitable for students aged 14 and older and is challenging to use for younger children. If a child creates an account, we collect the email address exclusively for password resetting and not for other purposes. We may also collect a username and password for login purposes. We comply with all legal requirements regarding the collection of personal information from children and provide parents or guardians the ability to access and manage this information.`,
         ro:`EdNeos nu colectează informații personale de la copii în scopuri comerciale. Aplicația este destinată mai mult elevilor ți studenților de la 14 ani. Este dificil de utilizat pentru cei mai mici de această vârstă. În cazul în care un copil își creează un cont, colectăm adresa de e-mail exclusiv pentru resetarea parolei și nu pentru alte scopuri. De asemenea, putem colecta un nume de utilizator și o parolă pentru scopuri de conectare. Respectăm toate cerințele legale referitoare la colectarea informațiilor personale de la copii și oferim părinților sau tutorilor posibilitatea de a accesa și de a gestiona aceste informații.`
      },
    130: {
         en:``,
         ro:``
      },
    131: {
         en:``,
         ro:``
      },
    132: {
         en:`9. Questions and Concerns`,
         ro:`9. Întrebări și Preocupări`
      },
    133: {
         en:`If you have any questions or concerns about our privacy practices, please contact us at hello@edneos.com. We are committed to addressing any issues related to privacy and data protection.`,
         ro:`Dacă aveți întrebări sau nelămuriri legate de practicile noastre de confidențialitate, vă rugăm să ne contactați la hello@edneos.com . Suntem dedicați la abordarea oricăror probleme legate de confidențialitate și protecția datelor.`
      },
    134: {
         en:`Appendix A – Definitions`,
         ro:`Anexa A – Definiții`
      },
    135: {
         en:`● "Affiliate": An entity that controls, is controlled by, or is under common control with Edneos.`,
         ro:`● "Afiliat": Entitate care controlează, este controlată de, sau se află sub control comun cu Edneos.`
      },
    136: {
         en:`● "Application": The mobile software/applications of Edneos.`,
         ro:`● "Aplicație": Software-ul/aplicațiile mobile ale Edneos.`
      },
    137: {
         en:`● "Child" or "Children": Children under 13 years of age in the U.S. and under 16 years of age in other jurisdictions.`,
         ro:`● "Copil" sau "Copii": Copii sub 13 ani în S.U.A. și sub vârsta de 16 ani în alte jurisdicții.`
      },
    138: {
         en:`● "Personal Information": Any information that identifies or can be used to identify a person.`,
         ro:`● "Informații personale": Orice informație care identifică sau poate fi utilizată pentru a identifica o persoană.`
      },
    139: {
         en:`● "Services": The services offered by Edneos, including any software available on our platform.`,
         ro:`● "Servicii": Serviciile oferite de Edneos, inclusiv orice software disponibil pe platforma noastră.`
      },
    140: {
         en:`● "User": Any individual or entity that uses Edneos services.`,
         ro:`● "Utilizator": Orice persoană sau entitate care utilizează serviciile Edneos.`
      },
};
export default generalTextTranslations;

//4.1
// 6.1