import classes from './Transform.module.scss';
import procent1 from "../../../../FILES/Images/Procent1.png";
import procent2 from "../../../../FILES/Images/Procent2.png";
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText'; 
import SectionCard from '../../../../COMPONENTS/UI/SectionCard';
import colors from '../../../../UTILS/colors';


function Transform(props){ 
    const text = useGetTranslatedText('homePage_TextTranslations.js');
return (
    <SectionCard backgroundColor={colors.section} textAlign={`left`} id={props.id}>
        <div className={classes.containerLeft}> 
            <div className={classes.containerQuestion}>
            {text[126]}
            </div>
            <div className={classes.containerData}>
                <div className={classes.item}>
                    <img className={classes.img} src={procent1} alt={'retention percentage'}/>
                    <div className={classes.imgFooter}>{text[127]}</div>
                </div>
                <div className={classes.item}>
                    <img className={classes.img} src={procent2} alt={'retention percentage'}/>    
                    <div className={classes.imgFooter}><span>{text[128]}</span><span style={{color:colors.primaryColor}}>{text[129]}</span><span>{text[130]}</span></div>
                </div>                  
            </div>            
            {/* <img className={classes.img} src={book} alt={'EdNeos logo robot'}/>     */}
        </div>
        <div className={classes.containerRight}>
            <h1 className={classes.h1}><span>{text[124]}</span></h1>
            <div className={classes.pp}>{text[125]}</div>                                          
        </div>
    </SectionCard> 

);
}


export default Transform;