
const objOfObjectsToArray= (objOfObjects) => {
    const rawArrayOfObj = Object.entries(objOfObjects).map((e) => ( { [e[0]]: e[1] } ));
    
    let arrayOfObj=[];
    
    rawArrayOfObj.forEach((obj) => {
        const oKey=Object.keys(obj)[0];
        const textObj=obj[oKey];
        arrayOfObj.push(textObj);
    });
   
    return arrayOfObj;
   };
   
   export default objOfObjectsToArray;
   