import IconSVG from '../IconSVG';

function Arrow1SvgIcon(props) {  
    
    const lineWidth=2;
    const svgWidth=12;
    const svgHeight=16;
        
    const d1="M1 13L11 3M11 3H2M11 3V12";
    return (
        <IconSVG 
        // id={"arrow1"}
        id={Math.random()}
            svgWidth={svgWidth}
            svgHeight={svgHeight}
            lineWidth={lineWidth}
            d1={d1}
            fill='none'
            fillColor = {props.fillColor}
            className={props.className}
            style={{...props.style}}
        />
    );
};

export default Arrow1SvgIcon;