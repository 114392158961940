
const colors= {
    section: `rgb(245, 245, 245)` ,
    primaryColor: '#1694D0',   
       
    };
    
    export default colors;
    
 
    
    