
import {useNavigate} from "react-router-dom";
import classes from './Logo.module.css';

function Logo () {
    const navigate = useNavigate();
    const gotoHomePage_Handler = () => {
        navigate('/');
    }
    return (
        <div className={classes.logoContainer}>
            <div className={classes.logo}  onClick={gotoHomePage_Handler}/>
        </div>
    );
};

export default Logo;
