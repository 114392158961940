const homePageTextTranslations= {
      /////////////////ForThoseWhoStudy//////////////
   0: {
         en:`For students and learners`,
         ro:`Pentru elevi și cursanți`
      },
   1: {
         en:`The mobile app designed for students, university students, and adult learners enhances personalized learning and long-term information retention. Download the app now, available on the Apple App Store and Google Play Store, for an improved educational journey through efficient knowledge consolidation.`,
         ro:`Aplicația mobilă concepută pentru elevi, studenți și adulți autodidacți stimulează învățarea auto-personalizată și reținerea pe termen lung a informațiilor. Descarcă acum aplicația, disponibilă în Apple App Store și Google Play Store, pentru un parcurs educațional îmbunătățit prin consolidarea eficientă a cunoștințelor!`
      },

   2: {
        en:`Customized Curriculum`,
        ro:`Curriculum personalizat`
      },         
   3: {
         en:`Creating an individual-centered educational journey`,
         ro:`Crearea parcursului educațional centrat pe individ`
      },
   4: {
         en:`Non-stop counseling`,
         ro:`Consiliere non stop`
      },
   5: {
         en:`Ask and receive answers whenever needed`,
         ro:`Întreabă și a primește răspunsuri oricând e nevoie`
      },
   6: {
         en:`Memory algorithms`,
         ro:`Algoritme de memorare`
      },
   7: {
         en:`Innovative methods for retaining large volumes of information in record time`,
         ro:`Metode inovative de reținere a volumelor mari de informații în timp record`
      },
   8: {
         en:`Learning cards`,
         ro:`Carduri de învățare`
      },
   9: {
         en:`Transform boring information into interactive cards for easier memorization`,
         ro:`Transformă informațiile plictisitoare în carduri interactive pentru a le memora mai ușor`
      },
   10:{
         en:`Complete learning guarantee`,
         ro:`Garanția învățării complete`
      },
   11:{
         en:`Learned information is assimilated for a lifetime`,
         ro:`Informațiile învățate sunt însușite pentru întreaga viață`
      },
   12:{
         en:`Lifelong Learning concept`,
         ro:`Conceptul Lifelong Learning`
      },
   13:{
         en:`Adapt educational materials according to the specificities of each individual`,
         ro:`Adaptează materialele educaționale în funcție de particularitățile fiecărui individ`
      }, 
/////////////////ForThoseWhoTeach//////////////
   14:{
         en:`For teachers and mentors`,
         ro:`Pentru profesori și mentori`
      },
   15:{
         en:`The web platform designed for teachers and mentors, as well as schools and companies, offers a wide range of tools and educational resources for efficient training and thorough assessment. Register now and discover how you can revolutionize the learning process with cutting-edge technology!`,
         ro:`Platforma web destinată profesorilor și mentorilor, dar și instituțiilor școlare și companiilor oferă o gamă largă de instrumente și resurse educaționale pentru instruire eficientă și evaluare atentă. Înregistrează-te acum și află cum poți revoluționa procesul de învățare prin tehnologia de ultimă generație!`
      },
   16:{
         en:`Knowledge Audit`,
         ro:`Auditul cunoștințelor`
      },
   17:{
         en:`Knowledge audit. Evaluate learners and develop innovative education programs without involving trainers.`,
         ro:`Knowledge audit. Evaluează cursanții şi elaborează programe de educație inovatoare fără implicarea trainerilor.`
      },
   18:{
         en:`AI-generated Content`,
         ro:`Conținut creat de AI`
      },
   19:{
         en:`Artificial intelligence helps you have the most suitable content - easily and without additional costs.`,
         ro:`Inteligența artificială te ajută să ai cel mai potrivit conținut - ușor și fără costuri suplimentare.`      
      },    
   20:{
         en:`Complete Learning Guarantee`,
         ro:`Garanția învățării complete`
      },
   21:{
         en:`Guarantee learner performance and better results in evaluations and exams.`,
         ro:`Garantează performanțele cursanților și rezultate mai bune la evaluări și examene.`
      },
   22:{
         en:`AI-based Knowledge Management`,
         ro:`Managementul cunoștințelor prin AI`
      },
   23:{
         en:`Upload PDF and DOCX files into the system, so the assistant is trained to answer learners correctly.`,
         ro:`Încarcă PDF și DOCX în sistem, astfel asistentul este instruit să răspundă corect cursanților.`
      },
   24:{
         en:`Notifications and Communication`,
         ro:`Notificari și comunicare`
      },
   25:{
         en:`Motivate learners through experience transfer and information.`,
         ro:`Motivarea cursanților prin transfer de experiență și informare.`
      },
   26:{
         en:`Vocal Educational Assistant`,
         ro:`Asistentul educațional vocal`
      },
   27:{
         en:`Communicate by voice and recognize voice responses provided by the learners, saving time for creativity.`,
         ro:`Comunică prin voce și recunoaște răspunsuri vocale oferite de cursanți, economisind timpul pentru creativitate.`
      },            
//////////////// Contact ////////////////////
   28:{
         en:`Do you have questions or collaboration proposals?`,
         ro:`Ai întrebări sau propuneri de colaborare?`
   }, 
   29:{
         en:`We are open to communication!`,
         ro:`Suntem deschiși spre comunicare!`
      }, 
   30:{
         en:`START NOW TO TRANSFORM INFORMATION INTO LASTING KNOWLEDGE!`,
         ro:`ÎNCEPE ACUM SĂ TRANSFORMI INFORMAȚIILE ÎN CUNOȘTINȚE DURABILE!`
      }, 
   31:{
         en:`FIRST NAME`,
         ro:`Prenume`
      }, 
   32:{
         en:`Enter your name`,
         ro:`Introduceți prenumele dvs.`
      }, 
   33:{
         en:`LAST NAME`,
         ro:`Numele de familie`
      }, 
   34:{
         en:`Enter your last name`,
         ro:`Introduceți numele de familie`
      }, 
   35:{
         en:`E-MAIL ADDRESS`,
         ro:`Adresa de email`
      }, 
   36:{
         en:`Enter your e-mail address`,
         ro:`Introduceți adresa dvs. de e-mail`
      }, 
   37:{
         en:`PHONE NUMBER`,
         ro:`Numar de telefon`
      }, 
   38:{
         en:`Enter your phone number`,
         ro:`Introduceți numarul dvs. de telefon`
      },    
   39:{
         en:`Send messsage`,
         ro:`Trimite`
      }, 
   40:{
         en:`Enter your message`,
         ro:`Introduceți mesajul`
      }, 
   41:{
         en:`Send message`,
         ro:`Trimite mesaj`
      },
   
   //////////////// Section Home //////////////////////
   42:{
      en:`Get started`,
      ro:`Începe acum`
      },
   43:{
         en:`EdNeos`,
         ro:`EdNeos`
      }, 
   44:{
         en:`Transform information into lasting knowledge!`,
         ro:`Transformă informațiile în cunoștințe durabile!`
      }, 
   45:{
         en:`EdNeos - where technology meets education. Maximize your cognitive abilities with our revolutionary solution for self-personalized learning, ensuring deep and lasting assimilation.`,
         ro:`EdNeos - unde tehnologia întâlnește educația. Valorifică-ți abilitățile cognitive cu soluția noastră revoluționară pentru învățarea auto-personalizată, asigurând o asimilare profundă și durabilă.`
      }, 
   46:{
         en:``,
         ro:``
      }, 
   47:{
         en:``,
         ro:``
      }, 
   48:{
         en:``,
         ro:``
      },                      
   49:{
         en:``,
         ro:``
      }, 
   50:{
         en:``,
         ro:``
      }, 
   51:{
         en:``,
         ro:``
      }, 
   52:{
         en:``,
         ro:``
      }, 
   53:{
         en:``,
         ro:``
      },  
   54:{
         en:``,
         ro:``
      }, 

//////////////// Section Benefits //////////////////////      
   55:{
         en:`Explore new subjects with AI through a personalized learning process!`,
         ro:`Explorează subiecte noi cu AI printr-un proces de învățare personalizat!`
      }, 
   56:{
         en:`Easily consolidate your knowledge by combining efficiency and enjoyment with vocal learning cards.`,
         ro:`Consolidează-ți mai ușor cunoștințele, îmbinând eficiența și plăcerea cu cardurile de învățare vocale.`
      }, 
   57:{
         en:`Advanced algorithms for easy retention and durable knowledge, applicable in everyday life.`,
         ro:`Algoritmi avansați pentru reținere ușoară și cunoștințe durabile, aplicabile în viața de zi cu zi.`
      }, 
   58:{
         en:`Discover how you can bring accumulated knowledge to life after teaching with EdNeos.`,
         ro:`Descoperă cum poți da viață cunoștințelor acumulate după predare cu EdNeos.`
      },  
   59:{
         en:`Use Learning Cards`,
         ro:`Folosește cardurile de învățare`
      },  
   60:{
         en:`Utilize Voice in Learning`,
         ro:`Utilizează vocea în învățare`
      },  
   61:{
         en:`Retrieve Forgotten Knowledge`,
         ro:`Recuperează cunoștințele uitate`
      },   
   62:{
         en:`A personalized educational journey through 5 levels of mastery: from Familiarization to Real-Life Application.`,
         ro:`Parcurs educațional personalizat prin 5 niveluri de stăpânire: de la Familiarizare la Aplicare în viață.`
      },    
   63:{
         en:`Receive answers and assistance for any question, anytime you need it, wherever you are.`,
         ro:`Primește răspunsuri și asistență la orice întrebare, oricând ai nevoie, oriunde te-ai afla.`
      }, 
   64:{
         en:`Transform large volumes of information into interactive cards for lasting and deep memorization.`,
         ro:`Transformă volumele mari de informații în carduri interactive pentru o memorare durabilă și profundă.`
      },
   65:{
         en:`Communicate with your virtual mentor: tell them what you know, ask them what you don't know, and receive personalized answers.`,
         ro:`Comunică cu mentorul tău virtual: spune-i ce cunoști, intreaba-l ce nu știi, și primește răspunsuri personalizate.`
      },
   66:{
         en:`Evaluate the depth of your understanding or the degree of material mastery and refresh your forgotten notions.`,
         ro:`Evaluează-ți profunzimea înțelegerii sau gradul de însușire a materialelor și reînnoiește-ți noțiunile uitate.`
      },
//////////////// Section Testimonials //////////////////////            
   67:{
         en:`How does`,
         ro:`Cum transformă`
      },  
   68:{
         en:`EdNeos`,
         ro:`EdNeos`
      }, 
   69:{
      en:`transform the educational experience?`,
      ro:`experiența educațională?`
   }, 
   70:{
      en:`medical student`,
      ro:`student la medicină`
   }, 
   71:{
      en:`high school student`,
      ro:`elevă de liceu`
   }, 
   72:{
      en:`teacher`,
      ro:`profesor`
   }, 
   73:{
      en:`“I use EdNeos because I am constantly faced with the challenge of quickly assimilating a large volume of medical terms and concepts. I need to memorize thousands of notions in a short span of time. EdNeos helps me do exactly that, offering me an optimized learning method.”`,
      ro:`“Folosesc EdNeos pentru că mă confrunt constant cu provocarea de a asimila rapid un volum mare de termeni și concepte medicale. Am nevoie să memorez mii de noțiuni într-un interval scurt de timp. EdNeos mă ajută să fac exact acest lucru, oferindu-mi o metodă optimizată de învățare.”`
}, 
   74:{
      en:`“Often, I'm not aware of my learning gaps. I used to take private tutoring sessions to discover what I don't know and to catch up on the material. I've given up on tutoring because EdNeos points out any notion I've forgotten and shows me how to recover the lost information. It's a mentor available 24/7.”`,
      ro:`“Adesea, nu sunt conștientă de lacunele mele în învățare. Eram nevoită să iau meditații pentru a descoperi ce nu știu și pentru a recupera materia. Am renunțat la meditații, deoarece EdNeos îmi indică orice noțiune pe care am uitat-o și îmi arată cum să recuperez informațiile pierdute. Este un mentor disponibil 24/7.”` 
},
   75:{
      en:`“I want to know where my students need help, either individually or as a group. I'm looking for concrete data and want a guarantee of learning efficiency. I recommend my students use the app, and I use the web version to have precise data. This gives me confidence that the learning process is deep and lasting.”`,
      ro:`“Vreau să știu unde studenții mei au nevoie de ajutor, fie individual, fie ca grup. Sunt în căutarea unor date concrete și doresc o garanție a eficienței învățării. Recomand elevilor mei să utilizeze aplicația, iar eu mă folosesc de versiunea web pentru a avea date exacte. Acest lucru îmi oferă încrederea că procesul de învățare este profund și durabil.”`
}, 
//////////////// Section TryApp //////////////////////                                            
   76:{
         en:`Try`,
         ro:`Încearcă aplicația`
      }, 
   77:{
         en:`EdNeos`,
         ro:`EdNeos`
      }, 
   78:{
         en:`App for free`,
         ro:`gratuit`
      }, 
   79:{
         en:`Let us show you how to turn information into lasting knowledge! With EdNeos, you can leverage your cognitive skills to their fullest potential.`,
         ro:`Lasă-ne să îți arătăm cum să transformi informațiile în cunoștințe durabile! Cu EdNeos îți poți valorifica abilitățile cognitive la potențialul maxim.`
      }, 
   80:{
         en:`Learner Account`,
         ro:`Cont de cursant`
      }, 
   81:{
         en:`Do you want to ensure that everything you learn is well ingrained in your mind? Download EdNeos, a revolutionary self-personalized learning system!`,
         ro:`Vrei să te asiguri că tot ceea ce înveți rămâne bine întipărit în minte? Descarcă EdNeos un sistem de învățare auto-personalizat revoluționar!`
      }, 
   82:{
         en:``,
         ro:``
      }, 
   83:{
         en:`● Knowledge retention and consolidation`,
         ro:`● Retenția și consolidarea cunoștințelor`
      }, 
   84:{
         en:`● Self-personalized learning system`,
         ro:`● Sistem de învățare auto-personalizat`
      },
   85:{
         en:`● Efficiency and autonomy in knowledge management`,
         ro:`● Eficiență și autonomie în gestionarea cunoștințelor`
      },
   86:{
         en:`● AI and voice recognition flashcards`,
         ro:`● AI și flashcard-uri cu recunoaștere vocală`
      },
   87:{
         en:`Mentor/Teacher Account`,
         ro:`Cont Mentor/Profesor`
      },  
   88:{
         en:`Passionate about teaching? EdNeos provides you with tools for impactful and lasting teaching`,
         ro:`Pasionat de predare? EdNeos îți oferă unelte pentru o predare care lasă urme adânci și durabile.`
      },
   89:{
         en:`● Continuous performance monitoring`,
         ro:`● Monitorizare continuă a performanței`
      },
   90:{
         en:`● AI support for interactive content`,
         ro:`● Suport AI pentru conținut interactiv`
      },
   110:{
         en:`● Knowledge consolidation and protection`,
         ro:`● Consolidare și protecție a cunoștințelor`
      },
   111:{
         en:`● Interactive learning with voice recognition`,
         ro:`● Învățare interactivă cu recunoaștere vocală`
      },          
   91:{
         en:`Institutional Account`,
         ro:`Cont Instituțional`
      },
   92:{
         en:`We support institutions aiming to integrate technology in education`,
         ro:`Susținem instituțiile care aspiră la integrarea tehnologiei în educație`
      },
   93:{
         en:`● Time and cost savings`,
         ro:`● Economisire timp și costuri`
      },
   112:{
         en:`● Effective knowledge consolidation`,
         ro:`● Consolidare eficientă a cunoștințelor`
      },
   113:{
         en:`● Student progress monitoring`,
         ro:`● Monitorizare progres cursanți`
      },
   114:{
         en:`● Interactive voice learning`,
         ro:`● Învățare interactivă vocală`
      },


   94:{
         en:`Create account`,
         ro:`Crează un cont`
      },                                        
//////////////// Section Questions //////////////////////
   95:{
         en:`Frequently asked Questions`,
         ro:`Întrebări frecvente`
      }, 
   96:{
         en:`We share a vision and commitment to help each other — and our customers — create unique learning experiences.`,
         ro:`We share a vision and commitment to help each other — and our customers — create unique learning experiences.`
      },       
   97:{
         en:`What is EdNeos?`,
         ro:`Ce este EdNeos?`
   },       
   98:{
         en:`Who is EdNeos for?`,
         ro:`Pentru cine este EdNeos?`
   },      
   99:{
         en:`How does EdNeos work?`,
         ro:`Cum funcționează EdNeos?`
      },       
   100:{
         en:`What subjects can you learn with EdNeos?`,
         ro:`Ce subiecte poți învăța cu EdNeos?`
      },       
   101:{
         en:`How can I start learning with EdNeos?`,
         ro:`Cum începi să înveți cu EdNeos?`
      },       
   102:{
         en:`EdNeos is the first AI educational assistant that guarantees complete learning. With unlimited topics and personalized learning methods, it guides you towards deep understanding and long-term retention of knowledge.`,
         ro:`EdNeos este primul asistent educațional AI care garantează învățarea completă. Cu subiecte nelimitate și metode de învățare personalizate, te ghidează spre o înțelegere profundă și o reținere durabilă a cunoștințelor.`
      },       
   103:{
         en:`EdNeos is a universal platform designed for students, teachers, schools, and companies. It can also be used on a larger scale by educational institutions to improve the performance of their students. Each type of user has their own personalized experience tailored to their needs.`,
         ro:`EdNeos este platforma universală destinată atât studenților și profesorilor, cât și instituțiilor școlare sau companii . EdNeos poate fi folosit și la scară mai largă de către instituțiile educaționale pentru a îmbunătăți performanța elevilor și a studenților. Fiecare tip de utilizator are parte de propria lui experiență personalizată conform nevoilor sale.`
      },       
   104:{
         en:`EdNeos uses a combination of advanced technologies to facilitate deep and lasting learning. The mobile app and web platform are powered by memorization algorithms and artificial intelligence, optimizing learning processes. Using data and neuroscience principles, EdNeos personalizes the educational experience, ensuring long-term knowledge retention and improving user performance.`,
         ro:`EdNeos utilizează o combinație de tehnologii avansate pentru a facilita învățarea profundă și durabilă. Aplicația mobilă și platforma web sunt alimentate de algoritmi de memorare și inteligență artificială, care optimizează procesele de învățare. Folosind date și principii de neuroștiință, EdNeos personalizează experiența educațională, asigurând reținerea cunoștințelor pe termen lung și îmbunătățind performanțele utilizatorilor.`
      },       
   105:{
         en:`With EdNeos, you can learn unlimited subjects by exploring different concepts with the AI-based assistant. Both mentors and students can create and customize study materials according to their specific needs, ensuring a tailored and diverse educational experience.`,
         ro:`Cu EdNeos, poți învăța subiecte nelimitate, deoarece poți explora diferite noțiuni  împreună cu asistentul bazat inteligența artificială. Atât mentorii, cât și studenții pot crea și personaliza materialele de studiu conform nevoilor lor specifice, asigurând o experiență educațională adaptată și diversificată.`
      },       
   106:{
         en:`To get started, register as a student or choose a mentor account for those who want to share their experience with others. You will receive immediate access to your EdNeos account along with all usage instructions. Explore and test our innovative solution, becoming one of the first to benefit from an advanced and personalized educational experience.`,
         ro:`Pentru a începe, înregistrează-te ca student sau alege contul de mentor pentru cei care vor sa ofere altor experiența sa. Vei primi acces imediat la contul tău EdNeos, împreună cu toate instrucțiunile de utilizare. Explorează și testează soluția noastră inovatoare, devenind printre primii care beneficiază de o experiență educațională avansată și personalizată.`
      }, 
   107:{
         en:`NAME`,
         ro:`Nume`
      }, 
   108:{
         en:`Enter your full name`,
         ro:`Introduceți numele dvs. complet`
      },  
   109:{
         en:`Forget less.`,
         ro:`Uită mai puțin.`
      },  
   115:{
         en:`Waiting list`,
         ro:`Listă de așteptare`
      },       
   116:{
         en:``,
         ro:``
      }, 
   117:{
         en:`Download on the App Store`,
         ro:`Descarcă din App Store`
      },   
   118:{
         en:`Download it on Google Play`,
         ro:`Descarcă de pe Google Play`
      }, 
   119:{
         en:`Create account`,
         ro:`Crează un cont`
      }, 
   120:{
         en:`Submit for Institutional Account`,
         ro:`Crează contul institutional`       
      },   
   121:{
         en:``,
         ro:``
      }, 
   122:{
         en:``,
         ro:``
      },
   123:{
         en:``,
         ro:``
      }, 

      // Section Transform
   124:{
         en:`Transform your educational journey with the innovation brought by EdNeos!`,
         ro:`Transformă-ți parcursul educațional cu inovația adusă de EdNeos!`
      }, 
   125:{
         en:`Through advanced technology such as artificial intelligence, data analysis, and neuroscience, you can accumulate an impressive amount of knowledge in record time. Choose this revolutionary system for personalized learning, and you will transform assimilated information into lasting knowledge and practical skills.`,
         ro:`Prin intermediul tehnologiei avansate, precum inteligența artificială, analiza datelor și neuroștiința, acumulezi o cantitate impresionantă de cunoștințe într-un timp record. Alege acest sistem revoluționar pentru o învățare auto-personalizată și vei transforma informațiile asimilate în cunoștințe durabile și abilități practice.`
      }, 
   126:{
         en:`How much knowledge is retained one year after teaching?`,
         ro:`Câte cunoștințe rămân după un an de la predare?`
      },
   127:{
         en:`With other platforms`,
         ro:`Cu alte platforme`
      }, 
   128:{
         en:`With `,
         ro:`Cu algoritmii `
      },
   129:{
         en:`EdNeos`,
         ro:`EdNeos`
      }, 
   130:{
         en:` algorithms`,
         ro:``
      }, 
      // Section End
   131:{
         en:`Learn more. Forget less.`,
         ro:`Află mai multe. Uită mai puțin.`
      }, 
   132:{
         en:`Advance faster.`,
         ro:`Avansează mai rapid.`
      },  
   133:{
      en:`Sign-up for free`,
      ro:`Înregistrează-te acum` 
      },                                       
};

    
    export default homePageTextTranslations;