import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./PAGES/home/HomePage";
import PrivacyPolicy from "./PAGES/privacy-policy/Privacy";
import TermsAndConditions from "./PAGES/terms-and-conditions/Terms";
// import Footer from './COMPONENTS/UI/Footer/Footer';
// import Header from './COMPONENTS/UI/Header/Header';
import SignUpReferral from "./PAGES/signup/SignUpReferral";
import RedirectPage from "./PAGES/redirect/RedirectPage";
import DeleteAccountPage from "./PAGES/delete-account/DeleteAccountPage";
// import useGetTranslatedText from './HOOKS/useGetTranslatedText'; 

function App() {    
    // const link = useGetTranslatedText('links_TextTranslations.js');

    return (
        <BrowserRouter>
            {/* <Header/> */}
                <Routes>
                    <Route path="/" index element={<HomePage/>} />
                    <Route path={'delete-account-form'} element={<DeleteAccountPage/>} />
                    <Route path={'terms-and-conditions'} element={<TermsAndConditions/>} />
                    <Route path={'termeni-si-conditii'} element={<TermsAndConditions/>} />
                    {/* <Route path={link[1]} element={<PrivacyPolicy/>} /> */}
                    <Route path={'privacy-policy'} element={<PrivacyPolicy/>} />
                    <Route path={'politica-de-confidentialitate'} element={<PrivacyPolicy/>} />
                    <Route path="ref/:refId" element={<SignUpReferral/>} /> 
                    <Route path="invite" element={<SignUpReferral/>} /> 
                    <Route path="redirect/:refId" element={<RedirectPage/>} /> 
                </Routes>
        </BrowserRouter>
    );
}

export default App;