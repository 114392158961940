
const errorsTextTranslations= {
    0:  {
            en:`An error occurred!`,
            ro:`A apărut o eroare!`
        },        
    1:  {
            en:`Please enter your e-mail!`,
            ro:`Introduce-ți o adresa de e-mail!`
        },
    2:  {
            en:`Please enter a valid email! `,
            ro:`Adresa de e-mail nu este validă!`
        },   
    3:  {
            en:`Password length must be 8 characters minimum! `,
            ro:`Parola trebuie să conțină minim 8 caractere!`
        }, 
    4:  {
            en:`Entered passwords do NOT match!`,
            ro:`Parolele introduse NU sunt identice!`
        },   
    5:  {
            en:`Sorry, an error occurred!`,
            ro:`Ne pare rău, a apărut o eroare!`
        },
    6:  {
            en:`A user with this e-mail address already exists!`,
            ro:`Un utilizator cu acest nume exista deja!`
        },        
    7:  {
            en:`The verification code must contain at least 5 digits! `,
            ro:`Codul de verificare trebuie să conțină minimum 5 cifre!`
        },  
    8:  {
            en:`Sorry, deletion failed! Please try again later!`,
            ro:`Ne pare rău, ștergerea nu a reușit! Incercați mai târziu!`
        },
        
       
};
    
    export default errorsTextTranslations;
    
 
    
    