import classes from './Testimonial.module.css';

function Testimonial(props){
    // console.log(props.picture);
return (
    <div className={classes.container}>
        <div className={classes.header}>
            <div className={classes.centered}>
                <img src={props.picture} className={classes.pic} alt={'avatar'}/>
                <h3>{props.name}</h3>
                <p>{props.title}</p>
            </div>
            
        </div>
        <div className={classes.footer}>
            <p>{props.text}</p>
        </div>
        
    </div>
);
}


export default Testimonial;    