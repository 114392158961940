import { Fragment } from 'react';
function PS (props){
    return (
        <Fragment>
            <p>{props.t}</p>
            <p>&nbsp;</p>
        </Fragment>
    );
};

export default PS;