
import DeleteAccountForm from './components/DeleteAccountForm';
// import BackgroundImage from '../../COMPONENTS/UI/Background/BackgroundImage';
// import backImg from '../../FILES/Backgrounds/backImgDeleteAccount.jpg';
import Logo from '../../COMPONENTS/UI/Logo';
import { Fragment } from 'react';

function DeleteAccountPage() {

    return (
        <Fragment>
            <Logo/>
        {/* <BackgroundImage vCentered backgroundImage={backImg}> */}
            <div style={{backgroundColor:'rgba(255,255,255,0.5)', width:'100%', height:'100%',}}>
                <DeleteAccountForm/>
            </div>
        {/* </BackgroundImage> */}
        </Fragment>        
    );
};

export default DeleteAccountPage;


