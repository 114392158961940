import classes from './Benefits.module.css';

import pic1 from "../../../../FILES/Images/pic1.png";
import pic2 from "../../../../FILES/Images/pic2.png";
import pic3 from "../../../../FILES/Images/pic3.png";
import pic4 from "../../../../FILES/Images/pic4.png";

import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
import SectionCard from '../../../../COMPONENTS/UI/SectionCard';
import SmallBox from './Components/SmallBox';
import BigBox from './Components/BigBox';

// import colors from '../../../../UTILS/colors';

function Benefits(props){ 
    const text = useGetTranslatedText('homePage_TextTranslations.js');
return (
    <SectionCard flexDirection={`column`} id={props.id}>
            <div className={classes.containerUp}>                  
                <SmallBox color={'#1694D0'} text={text[55]} picture={pic1}/>
                <BigBox color={'#2C77AE'} text={text[56]} picture={pic2}/>              
            </div>
            <div className={classes.containerDown}>
                <BigBox color={'#8CC3EA'} text={text[57]} picture={pic3}/>              
                <SmallBox switchOrder color={'#FDAB3D'} text={text[58]} picture={pic4}/>   
            </div>

    </SectionCard> 

);
}


export default Benefits;