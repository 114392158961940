

function iOS() {
  console.log('navigator.platform',navigator.platform);
  console.log('navigator.userAgent',navigator.userAgent);
    return [ //true/false
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
   
export default iOS;
   