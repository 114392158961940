import classes from './SmallBox.module.css';

function SmallBox(props){
    // console.log(props.picture);
return (
    <div className={classes.containerSmallBox} style={{backgroundColor:props.color}}>                  
        {!props.switchOrder && <div className={classes.containerSmallBoxText}>                  
            <h2 className={classes.h2}><span>{props.text}</span></h2>
        </div>}
        <div className={classes.containerSmallBoxPicture}>                  
            <img className={classes.img} src={props.picture} alt={'EdNeos Benefit'}/>    
        </div>
        {props.switchOrder && <div className={classes.containerSmallBoxText}>                  
            <h2 className={classes.h2}><span>{props.text}</span></h2>
        </div>}        
    </div>
);
}


export default SmallBox;    