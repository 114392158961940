import React from 'react';
import classes from './ButtonX.module.css';

function ButtonX(props) {
    return (
        <button
            type={props.type || 'button'}
            className={`${classes.button} ${props.className}`}
            onClick={props.onClose}
            disabled={props.disabled}
        >X
            {props.children}
        </button>
  );
};

export default React.memo(ButtonX);