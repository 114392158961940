import classes from './End.module.scss';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText'; 
import SectionCard from '../../../../COMPONENTS/UI/SectionCard';
import ButtonWithArrow from '../../../../COMPONENTS/UI/Buttons/ButtonWithArrow';

function End(props){ 
    const text = useGetTranslatedText('homePage_TextTranslations.js');
return (
    <SectionCard flexDirection ={'column'} textAlign={`center`} id={props.id}>
        <div className={classes.containerText}> 
            <h1 className={classes.h1}><span>{text[131]}</span></h1>
            <h1 className={classes.h1}><span>{text[132]}</span></h1>           
        </div>
        <div className={classes.containerButton}>
            <ButtonWithArrow className={`${classes.button}`} onClick={() =>window.open('https://app.edneos.com/auth/get-started ', '_blank', 'noopener, noreferrer')}><span>{text[133]}</span></ButtonWithArrow>
        </div>
    </SectionCard> 
);
}


export default End;