import classes from './BigBox.module.css';

function BigBox(props){
    // console.log(props.picture);
return (
    <div className={classes.containerBigBox} style={{backgroundColor:props.color}}>                  
        <div className={classes.containerBigBoxText}>                  
            <h2 className={classes.h2}><span>{props.text}</span></h2>
        </div>
        <div className={classes.containerBigBoxPicture}>                  
            <img className={classes.img} src={props.picture} alt={'EdNeos Benefit'}/>    
        </div>                           
    </div>   
);
}


export default BigBox;    