import { Fragment } from 'react';

function PBS (props){
    return (
        <Fragment>
            <p><b>{props.t}</b></p>
            <p>&nbsp;</p>
        </Fragment>
    );
};

export default PBS;