import classes from './Testimonials.module.css';

import avatar1 from "../../../../FILES/Images/avatar1.png";
import avatar2 from "../../../../FILES/Images/avatar2.png";
import avatar3 from "../../../../FILES/Images/avatar3.png";

import Testimonial from './Components/Testimonial';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
import SectionCard from '../../../../COMPONENTS/UI/SectionCard';
import colors from '../../../../UTILS/colors';

function Testimonials(props){ 
    const text = useGetTranslatedText('homePage_TextTranslations.js');
return (
    <SectionCard backgroundColor={colors.section} flexDirection={`column`} id={props.id}>
            <div className={classes.containerText}>                  
                <h1><span>{text[67]}</span> <span  style={{color:colors.primaryColor}}>{text[68]}</span></h1>
                <h1>{text[69]}</h1>    
            </div>
            <div className={classes.containerTestimonials}>                  
                <Testimonial name="George" picture={avatar1} title={text[70]} text={text[73]} circleColor={`rgb(236,237,255)`}/>
                <Testimonial name="Patricia" picture={avatar2} title={text[71]} text={text[74]} circleColor={`rgb(255,246,213)`}/>
                <Testimonial name="Richard" picture={avatar3} title={text[72]} text={text[75]} circleColor={`rgb(255,234,233)`}/>
            </div>
    </SectionCard> 

);
}


export default Testimonials;