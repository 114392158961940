//https://www.sarasoueidan.com/blog/css-svg-clipping/
//https://www.svgrepo.com/
import classes from './IconSVG.module.css';

function IconSVG (props){    
    const {id, svgWidth, svgHeight, lineWidth,
        fill, fillColor,
        d1, d2, d3, d4, d5, d6, d7, d8, d9, d10, d11, d12, d13,
        circle1,
        rect1,
        line1, line2,
        polyPoints1, polyPoints2, polyPoints3, polyPoints4
    } = props;

return (
    <svg className={`${classes.animation} ${props.className}`} 
        style={{...props.style}}
        //  style={{width: props.width, height:props.height, filter:`${props.shadow ? 'drop-shadow(0px 10px 5px rgba(0,0,0,0.5))' : 'none'}`}}         
         viewBox={`0 0 ${svgWidth} ${svgHeight}`}         
        //  fill={fill}
         xmlns="http://www.w3.org/2000/svg">            
            <mask id={id} style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse"   fill={fill}>
                <path d={d1} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round" transform={props.transform}/>
                <path d={d2} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d={d3} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d={d4} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>                
                <path d={d5} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d={d6} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d={d7} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d={d8} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d={d9} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d={d10} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d={d11} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d={d12} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d={d13} strokeWidth={lineWidth} stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                
                <circle cx={circle1?.cx} cy={circle1?.cy} r={circle1?.r}/>
                <polygon points={polyPoints1}/>
                <polygon points={polyPoints2}/>
                <polygon points={polyPoints3}/>
                <polygon points={polyPoints4}/>
                {/* <rect x={rect1.x} y={rect1.y} width={rect1.width} height={rect1.height}/> */}
                <rect x={rect1?.x} y={rect1?.y} width={rect1?.width} height={rect1?.height}/>
                <line strokeWidth={lineWidth} stroke={"black"} x1={line1?.x1} y1={line1?.y1} x2={line1?.x2} y2={line1?.y2}/>
                <line strokeWidth={lineWidth} stroke={"black"} x1={line2?.x1} y1={line2?.y1} x2={line2?.x2} y2={line2?.y2}/>
            </mask>            
            <g mask={`url(#${id})`}>
                <rect width={svgWidth} height={svgHeight}/>{/* fill={color} */}
            </g>            
    </svg>        
);
};

export default IconSVG;

