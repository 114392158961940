// import classes from './H3.module.css';
import { Fragment } from 'react';

function H3 (props){
    return (
        <Fragment>
            <h3><b>{props.t}</b></h3>
            <p>&nbsp;</p>
        </Fragment>
    );
};

export default H3;