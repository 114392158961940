import { Fragment } from 'react';
import classes from './DropNavigationMenu.module.scss';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText';
import { scrollToSection } from '../../../../UTILS/scrollToSection';
import NavigationMenu1 from './NavigationMenu1';
import ButtonWithArrow from '../../Buttons/ButtonWithArrow';
import NavigationMenu1Items from './NavigationMenu1Items';

function DropNavigationMenu (props) { 
    const text = useGetTranslatedText('general_TextTranslations.js');  
return (
    <Fragment>    
        <div className={classes.backdrop} onClick={props.onClose}/>
        <div className={`${classes.modal} ${window.scrollY > 10 ? classes.active:''}`}>
            <ul className={classes.ulNav}>
            <NavigationMenu1Items className={classes.items} onClose={()=>props.onClose()}/>
                {/* <li className={props.className} onClick={() =>onClick_Handler('why_edneos')}>{text[4]}</li>
                <li className={props.className} onClick={() =>onClick_Handler('product')}>{text[5]}</li>
                <li className={props.className} onClick={() =>onClick_Handler('solution')}>{text[6]}</li>
                <li className={props.className} onClick={() =>onClick_Handler('contact')}>{text[9]}</li>
                         */}
                {/* <NavOptions className={`${classes.liMainNav} ${classes.hideOnMobile}`} onClose={props.onClose}/> */}
    
                <ButtonWithArrow className={`${classes.button} ${classes.hideOnMobile}`} onClick={() =>window.open('https://app.edneos.com/auth/get-started ', '_blank', 'noopener, noreferrer')}><span>{text[10]}</span></ButtonWithArrow>
            </ul>   

        </div>
    </Fragment>
);
};

export default DropNavigationMenu;
