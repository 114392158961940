
import SignUpForm from './components/SignUpForm';
import BackgroundImage from '../../COMPONENTS/UI/Background/BackgroundImage';
import backImg from '../../FILES/Backgrounds/backImgReferral.webp';


function SignUpReferral() {

    return (
        <BackgroundImage vCentered backgroundImage={backImg}>
            <SignUpForm/>
        </BackgroundImage>
    );
};

export default SignUpReferral;


