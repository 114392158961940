import classes from './Questions.module.css';
import Question from './Components/Question';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText'; 
import SectionCard from '../../../../COMPONENTS/UI/SectionCard';
import askPic from "../../../../FILES/Images/questionPic.png";

function Questions(props){ 
    const text = useGetTranslatedText('homePage_TextTranslations.js');
return (
    <SectionCard id={props.id}>        
            <div className={classes.containerText}>  
                <h1>{text[95]}</h1>
                <p>{text[96]}</p>
                <Question question={text[97]} answer={text[102]}/>
                <Question question={text[98]} answer={text[103]}/>
                <Question question={text[99]} answer={text[104]}/>
                <Question question={text[100]} answer={text[105]}/>
                <Question question={text[101]} answer={text[106]}/>                
            </div>            
            <div className={classes.containerPic}>  
            <img className={classes.img} src={askPic} alt={'EdNeos application screenshots'} />    
            </div>
    </SectionCard> 

);
}


export default Questions;