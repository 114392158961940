import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText'; 
// import contactImg from "../../../assets/images/contact-us.png";
import qrPicture from "../../../../FILES/Images/QR.png";
import {useState} from 'react';
import axios from "axios";
// import "../../../assets/css/bulma.min.css";
import SvgArrow2 from '../../../../COMPONENTS/SVG/SvgArrow2';
import InputContact from '../../../../COMPONENTS/UI/Inputs/InputContact';
import classes from './Contact.module.scss';
import SectionCard from '../../../../COMPONENTS/UI/SectionCard';
import colors from '../../../../UTILS/colors';
import ButtonWithArrow from '../../../../COMPONENTS/UI/Buttons/ButtonWithArrow';

function Contact(props){ 
    const text = useGetTranslatedText('homePage_TextTranslations.js');

    const validateEmail = (email) => {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    };
 
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    // const validatePhoneNumber = (number) => {
    //     const pattern = /^(?=\d{1,7}[-\s]?\d{0,7}$).{8,15}$/;
    //     return pattern.test(number);
    // };

    const sendMessage=async()=>{
        try {
          if(!formValues.email){
              alert('Please enter your email.');
              return
          }
            if(!validateEmail(formValues.email)){
                alert('Please enter a valid email.');
                return
            }
            if(!formValues.name){
                alert('Please enter a name.');
                return
            }
          //   if(formValues.phone && !validatePhoneNumber(formValues.phone)){
          //       alert('Please enter a valid phone number.');
          //       return
          //   }
            const formData = new FormData();
            formData.append('name', formValues.name);
            formData.append('email', formValues.email);
            formData.append('phone', formValues.phone);
            formData.append('message', formValues.message);

            const response = await axios.post(
                'https://api.edneos.com/api_landing/LandingSendForm.php',
                formData,
                {
                    headers: {
                        LToken: 'c64232149b31464e803c078da1faca67',
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
          if(response.data.status==='ok'){
              alert('Message sent successfully.');
              setFormValues({
                  name:'',
                  email:'',
                  phone:'',
                  message:'',

              })
              return
          }
             if(response.data.error){
                 alert(response.data.error);
                 return
             }
            alert('Sorry, an error occurred.');

        } catch (error) {
            alert('Sorry, an error occurred.');
        }
    }
      
return (
<SectionCard backgroundColor={colors.section} textAlign={`left`} id={props.id}>
    <div className={classes.containerLeft}> 
        <div className={classes.containerText}>{text[30]} </div>
        <div className={classes.containerPicture}>
            <img className={classes.img} src={qrPicture} alt={'QR Code'}/>
        </div>            
        {/* <img className={classes.img} src={book} alt={'EdNeos logo robot'}/>     */}
    </div>
    <div className={classes.containerRight}>
        <h1 style={{fontSize:'33px'}} className={classes.h1}><span>{text[28]}</span></h1>
        <h1 style={{fontSize:'33px'}} className={classes.h1}><span>{text[29]}</span></h1>
        <div className={classes.formContainer}>
            <InputContact label={text[107]} input={{name:'name', id: 'name', type: 'text', value:`${formValues.name}`, placeholder:text[108], required:true }} onChange={handleChange} />
            <InputContact label={text[35]} input={{name:'email', id: 'email', type: 'text', value:`${formValues.email}`, placeholder:text[36], required:true }} onChange={handleChange} />
            <InputContact label={text[37]} input={{name:'phone', id: 'phone', type: 'text', value:`${formValues.phone}`, placeholder:text[38], required:true }} onChange={handleChange} />

            <div className="field" style={{marginTop: 40}}>
                <label className="label">{text[39]}</label>
                <div className="control">
                    <textarea 
                        className="textarea has-fixed-size"
                        onChange={handleChange}
                        id="message"
                        name="message"
                        value={formValues.message}
                        placeholder={text[40]}>
                    </textarea>
                </div>
            </div>
            <ButtonWithArrow className={classes.button} onClick={sendMessage}><span>{text[39]}</span></ButtonWithArrow>
            {/* <a className="button is-primary main-page-button" onClick={sendMessage}>
                <span>{text[39]}<SvgArrow2/></span>
            </a> */}

        </div>                       
    </div>
</SectionCard>

    // <section id="contact" className="hero contact is-primary" >
    //     <div className="hero-body" style={{backgroundColor: 'white'}}>
    //         <div className="container">
    //             <div className="columns">
    //                 <div className="column contact-left">
    //                     {/* <p>{text[30]}<a href="tel:+40756911166"><b>+40756911166</b></a></p> */}
    //                     <p>{text[30]}</p>
    //                     {/* <a href="mailto:info@edneos.com">info@edneos.com</a> */}
    //                     <img src={envelopeImg} alt={'envelop symbol'}/>
    //                 </div>

    //                 <div className="column">   
    //                 <h2 style={{fontSize:'33px'}}>{text[28]}</h2>
    //                     <h2 style={{fontSize:'33px'}}>{text[29]}</h2>                 
    //                 <InputContact label={text[107]} input={{name:'name', id: 'name', type: 'text', value:`${formValues.name}`, placeholder:text[108], required:true }} onChange={handleChange} />
    //                 <InputContact label={text[35]} input={{name:'email', id: 'email', type: 'text', value:`${formValues.email}`, placeholder:text[36], required:true }} onChange={handleChange} />
    //                 <InputContact label={text[37]} input={{name:'phone', id: 'phone', type: 'text', value:`${formValues.phone}`, placeholder:text[38], required:true }} onChange={handleChange} />

    //                     <div className="field" style={{marginTop: 40}}>
    //                         <label className="label">{text[39]}</label>
    //                         <div className="control">
    //                             <textarea className="textarea has-fixed-size"
    //                                 onChange={handleChange}
    //                                 id="message"
    //                                 name="message"
    //                                 value={formValues.message}
    //                                 placeholder={text[40]}></textarea>
    //                         </div>
    //                     </div>
    //                     <a className="button is-primary main-page-button" onClick={sendMessage}>
    //                         <span>{text[39]}<SvgArrow2/></span>
    //                     </a>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </section>

);
}



export default Contact;                        