
import React from 'react';
import "../../../assets/css/bulma.min.css";

// import classes from './InputLabelEffect.module.scss';

const InputContact = React.forwardRef((props, ref) => {
  return (
    <div className="field" style={{marginTop: 40}}>
        <label className="label">{props.label}</label>
        <div className="control">
            <input className="input" onChange={props.onChange}
                {...props.input}/>
        </div>
    </div>
  );
});

export default InputContact;
