import axios from "axios";
import classes from './SignUpForm.module.scss';

import { useState, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import GlassCardClose from '../../../COMPONENTS/UI/Cards/GlassCardClose';
import InputLabelEffect from '../../../COMPONENTS/UI/Inputs/InputLabelEffect';
import Button from '../../../COMPONENTS/UI/Buttons/Button';

import useGetTranslatedText from '../../../HOOKS/useGetTranslatedText'; 

 
function SignUpForm () {
    const text = useGetTranslatedText('general_TextTranslations.js');
    const errorText = useGetTranslatedText('errors_TextTranslations.js');
    
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate ();  
    
    const params = useParams();
    const { refId }= params;
    const [searchParams] = useSearchParams()
    
    const emailInputRef = useRef();
    const passwordInputRef = useRef();
    const confirmPasswordInputRef = useRef();

    const validateEmail = (email) => {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        const enteredEmail = emailInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;
        const enteredConfirmedPassword = confirmPasswordInputRef.current.value;
        
        // Validation

        if(!enteredEmail){ alert(errorText[1]); return; }
        if(!validateEmail(enteredEmail)){alert(errorText[2]); return; }
        if(enteredPassword.length < 8 ){alert(errorText[3]); return; }
        if(enteredPassword !== enteredConfirmedPassword){alert(errorText[4]); return; }


        const formData = new FormData();            
            formData.append('email', enteredEmail);
            formData.append('password', enteredPassword);
            formData.append('ref', refId || searchParams.get('ref'));

        setIsLoading(true);
        // LoadingSpinner

        try {
            const response = await axios.post(
                'https://api.edneos.com/api/AppRegister.php',
                formData,
                 {headers: {'Content-Type': 'multipart/form-data'}}
            );
            if(response.data.status==='ok'){
                alert(text[17]);
                setIsLoading(false);
                const refToken=response.data.ref_token;

                // navigate('https://api.edneos.com/demoref/process/'+encodeURIComponent(refToken));
                // alert('Redirect');
                navigate('/redirect/'+encodeURIComponent(refToken));

                return;
            }
            if(response.data.error){
                setIsLoading(false);
                switch (response.data.error) {
                    case 'Username already exists':
                        alert(errorText[6]);
                        break;
                    default:
                        
                        break;
                }
            
                // Username already exists
                return;
            }
            alert(errorText[5]);
            setIsLoading(false);
        } catch (err) {
            alert(errorText[5]);
            alert(err.message);
            setIsLoading(false);
        }
    }

    return (
        <GlassCardClose navigateOnClose={'/'}>
            <h1 className={classes.h1}>{text[11]}</h1>
            <form onSubmit={submitHandler} method="POST">
                <InputLabelEffect label={text[12]} input={{name:'email', id: 'email', type: 'email', required:true }} ref={emailInputRef} />
                <InputLabelEffect label={text[13]} input={{name:'password', id: 'password', type: 'password', required:true }} ref={passwordInputRef}/>
                <InputLabelEffect label={text[14]} input={{name:'confirmPassword', id: 'confirmPassword', type: 'password', required:true }} ref={confirmPasswordInputRef}/>                
                <div className={classes.actions}>
                    {!isLoading && <Button type='submit' >{text[15]}</Button>}
                    {isLoading && <p>{text[16]}</p>}
                </div>
            </form>
        </GlassCardClose>    
    );
};

export default SignUpForm;
