import classes from './EdNeosHome.module.css';
import book from "../../../../FILES/Images/bookIPhone.png";
// import appStoreImg from "../../../../FILES/Images/AppStore.png";
// import googlePlayImg from "../../../../FILES/Images/GooglePlay.png";
// import ButtonWithImg from '../../../../COMPONENTS/UI/Buttons/ButtonWithImg';
import ButtonWithArrow from '../../../../COMPONENTS/UI/Buttons/ButtonWithArrow';
// import { scrollToSection } from '../../../../UTILS/scrollToSection';
import useGetTranslatedText from '../../../../HOOKS/useGetTranslatedText'; 
import SectionCard from '../../../../COMPONENTS/UI/SectionCard';
import colors from '../../../../UTILS/colors';
// import DOMPurify from 'dompurify';

function EdNeosHome(props){ 
    const text = useGetTranslatedText('homePage_TextTranslations.js');
    // const sanitizedDataText49 = () => ({__html: DOMPurify.sanitize(text[49]) });
    // const sanitizedDataText51 = () => ({__html: DOMPurify.sanitize(text[51]) });
    // const sanitizedDataText53 = () => ({__html: DOMPurify.sanitize(text[53]) });
return (
    <SectionCard backgroundColor={colors.section} textAlign={`left`} id={props.id}>
            <div className={classes.containerText}>
                {/* <h3>{text[42]}</h3> */}
                <h1 className={classes.h1}><span>{text[44]}</span></h1>
                <div className={classes.pp}>{text[45]}</div>
                <div className={classes.installAppButtons}>
                    {/* <ButtonWithImg imgSrc={googlePlayImg}/>
                    <ButtonWithImg imgSrc={appStoreImg}/>     */}
                    <ButtonWithArrow onClick={() =>window.open('https://app.edneos.com/auth/get-started ', '_blank', 'noopener, noreferrer')}><span>{text[42]}</span></ButtonWithArrow>
                </div>                                          
            </div>
            <div className={classes.containerImg}>  
                <img className={classes.img} src={book} alt={'EdNeos logo robot'}/>    
            </div>
        
    </SectionCard> 

);
}


export default EdNeosHome;