import React from 'react';

import classes from './Background.module.css';

function Background (props){
    return (
        <div className={`${classes.background} ${props.className}`} >
            {props.children}
        </div>
    );
};

export default React.memo(Background);