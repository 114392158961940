
import Logo from '../../COMPONENTS/UI/Logo';
import { Link } from 'react-router-dom';
import classes from './Privacy.module.css';
import Background from '../../COMPONENTS/UI/Background/Background';
import Footer from '../../COMPONENTS/UI/Footer/Footer';
import useGetTranslatedText from '../../HOOKS/useGetTranslatedText'; 

import PIS from '../../COMPONENTS/UI/Texts/PIS';
import PI from '../../COMPONENTS/UI/Texts/PI';
import P2I from '../../COMPONENTS/UI/Texts/P2I';
import P2IS from '../../COMPONENTS/UI/Texts/P2IS';
import PB from '../../COMPONENTS/UI/Texts/PB';
import PBS from '../../COMPONENTS/UI/Texts/PBS';
import H3 from '../../COMPONENTS/UI/Texts/H3';
import S from '../../COMPONENTS/UI/Texts/S';
import S2 from '../../COMPONENTS/UI/Texts/S2';
import TI from '../../COMPONENTS/UI/Texts/TI';

function Privacy() {
    const text = useGetTranslatedText('privacyPage_TextTranslations.js');
    const link = useGetTranslatedText('links_TextTranslations.js');
    return (        
        <Background>
            <Logo/>
            <div className={classes.container}>
                <H3 t={text[1]}/>
                    
                    {/* 1 */}
                    <S/>
                    <PBS t={text[2]}/>
                        <PI t={text[3]}/>
                        <PIS t={text[4]}/>
                        <PB t={text[5]}/>
                            <PI t={text[6]}/>
                            <P2I t={text[7]}/>
                            <P2I t={text[8]}/>

                    {/* 2 */}
                    <S2/>
                    <PBS t={text[11]}/>
                        <PI t={text[12]}/>
                        <PI t={text[13]}/>
                        <PIS t={text[14]}/>

                    {/* 3 */}
                    <S2/>
                    <PBS t={text[17]}/>
                        <PIS t={text[18]}/>
                        <PB t={text[19]}/>
                            <PI t={text[20]}/>
                            <PI t={text[21]}/>
                            <PI t={text[22]}/>
                            <PI t={text[23]}/>
                            <PI t={text[24]}/>
                            <PI t={text[25]}/>
                            <PIS t={text[26]}/>
                        <PB t={text[27]}/>
                            <PI t={text[28]}/>
                            <PI t={text[29]}/>
                            <PIS t={text[30]}/>
                        <PB t={text[31]}/>
                            <PI t={text[32]}/>
                            <PIS t={text[33]}/>            
                        <PB t={text[34]}/>
                            <PI t={text[35]}/>
                            <PIS t={text[36]}/>            

                    {/* 4 */}
                    <S2/>
                    <PBS t={text[39]}/>
                        <PB t={text[40]}/>
                            <PI t={text[41]}/>
                                <P2I t={text[42]}/>
                                <P2IS t={text[43]}/>
                            <PB t={text[44]}/>
                                <PIS t={text[45]}/>
                        <PB t={text[46]}/>
                            <PI t={text[47]}/>
                                <P2I t={text[48]}/>
                                <P2I t={text[49]}/>
                                <P2I t={text[50]}/>
                                <P2I t={text[51]}/>
                                <P2IS t={text[52]}/>  
                        <PB t={text[53]}/>
                            <PI t={text[54]}/>

                    {/* 5 */}
                    <S2/>
                    <PBS t={text[57]}/>
                        <PIS t={text[58]}/>
                        <PB t={text[59]}/>
                            <PIS t={text[60]}/>
                        <PB t={text[61]}/>
                            <PIS t={text[62]}/>
                        <PB t={text[63]}/>
                            <PIS t={text[64]}/>
                        <PB t={text[65]}/>
                            <PI t={text[66]}/>
                                <P2I t={text[67]}/>
                                <P2I t={text[68]}/>
                            <PI t={text[69]}/>
                                <P2I t={text[70]}/>
                                <P2I t={text[71]}/>
                            <PI t={text[72]}/>
                                <P2I t={text[73]}/>
                                <P2I t={text[74]}/> 
                            <PI t={text[75]}/>
                                <P2I t={text[76]}/>
                                <P2I t={text[77]}/>
                            <PI t={text[78]}/>
                                <P2I t={text[79]}/>
                                <P2I t={text[80]}/>
                            <PI t={text[81]}/>
                                <P2I t={text[82]}/>
                                <P2I t={text[83]}/>
                            <PI t={text[84]}/>
                                <P2I t={text[85]}/>
                                <P2IS t={text[86]}/>                                                                                                                                
                        <PB t={text[87]}/>
                            <PI t={text[88]}/>
                                <P2I t={text[89]}/>
                                <P2I t={text[90]}/>
                                <P2I t={text[91]}/>
                                <P2I t={text[92]}/>
                                <P2I t={text[93]}/>

                    {/* 6 */}
                    <S2/>
                    <PBS t={text[96]}/>
                        <PIS t={text[97]}/>
                        <PB t={text[98]}/>
                            <PI t={text[99]}/>
                            <PI t={text[100]}/>
                            {/* <PI t={text[101]}/> */}
                            {/* <PI t={text[101]}/> */}
                            <TI t={text[101]}/>
                            <Link to={`/${link[2]}`}>
                                <span style={{color: 'blue', textDecoration: 'underline' }}>{text[122]}</span>
                            </Link>
                            <PIS t={text[102]}/>
                        <PB t={text[103]}/>
                            <PIS t={text[104]}/>
                        <PB t={text[105]}/>
                            <PIS t={text[106]}/>
                        <PB t={text[107]}/>
                            <PI t={text[108]}/>
                            <PI t={text[109]}/>
                            <PIS t={text[110]}/>    
                        <PB t={text[111]}/>
                            <PI t={text[112]}/>
                            <PIS t={text[113]}/> 
                        <PB t={text[114]}/>
                            <PIS t={text[115]}/>   
                        <PB t={text[116]}/>
                            <PIS t={text[117]}/>   
                        <PB t={text[118]}/>
                            <PIS t={text[119]}/>
                        <PB t={text[120]}/>
                            <PIS t={text[121]}/>

                    {/* 7 */}
                    <S2/>
                    <PBS t={text[124]}/>
                        <PIS t={text[125]}/>

                    {/* 8 */}
                    <S2/>
                    <PBS t={text[128]}/>
                        <PIS t={text[129]}/>

                    {/* 9 */}
                    <S2/>
                    <PBS t={text[132]}/>
                        <PIS t={text[133]}/>     


                    {/* Anexa A */}
                    <S2/>
                    <PBS t={text[134]}/>
                        <PI t={text[135]}/>  
                        <PI t={text[136]}/> 
                        <PI t={text[137]}/> 
                        <PI t={text[138]}/> 
                        <PI t={text[139]}/> 
                        <PI t={text[140]}/> 
            </div>
            <Footer/>
        </Background>
    );
}

export default Privacy;
