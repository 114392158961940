import { Fragment } from 'react';

function P2IS (props){

    return (
        <Fragment>
            <p style={{textIndent: '10vw'}}>{props.t}</p>
            <p>&nbsp;</p>
        </Fragment>
    );
};

export default P2IS;