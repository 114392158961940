import React from 'react';

import classes from './BackgroundImage.module.css';

function BackgroundImage (props){
    return (
        <div className={`${classes.background} ${props.className}`} style={{backgroundImage: `url(${props.backgroundImage})`}}>
            {props.children}
        </div>
    );
};

export default React.memo(BackgroundImage);