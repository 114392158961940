import { configureStore } from '@reduxjs/toolkit';

import storeGeneral_Reducer from './storeGeneral';
// import storeAuth_Reducer from './storeAuth';



const store = configureStore({
  reducer: { 
    generalStore: storeGeneral_Reducer,
    // authStore: storeAuth_Reducer,

  },
});

export default store;
