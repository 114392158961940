// import classes from './H3.module.css';
import { Fragment } from 'react';

function S2 (){
    return (
        <Fragment>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </Fragment>
    );
};

export default S2;